//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Image } from './Control/Vue/ResponsiveImage';

export namespace MiaPlaza.SignalR {
	export abstract class Configuration
	{
		public static PATH: string = `/signalr`;
	}
	export class FundsHub
	{
		public static PATH: string = `/fundsHub`;
	}
	export class MultiplayerGameHub
	{
		public static PATH: string = `/multiplayerGameHub`;
	}
	export class SessionHub
	{
		public static PATH: string = `/sessionHub`;
		public static SESSION_TIMEZONE_SET: string = `timezone_set`;
	}
	export interface IFundsUpdate
	{
		Balance: number;
		TimeStamp: string;
		PlaySound: boolean;
	}
	export interface InvitationResponse
	{
		Status: number;
		Instance: string;
	}
}
export namespace MiaPlaza.Parents.Control.Vue {
	export interface IMohsStudent
	{
		FirstName: string;
		SapLink: string;
	}
	export interface IGlyphiconMenuItem
	{
		Name: string;
		NavigateUrl: string;
		GlyphiconName: string;
		IsSelected: boolean;
	}
	export interface IChild
	{
		Name: string;
		NavigateUrl: string;
		ProfilePicture: Image;
		IsSelected: boolean;
		IsActiveMohsStudent: boolean;
	}
}
export namespace MiaPlaza.Parents.Control.Vue.Notifications {
	export interface IChildIsGrounded extends MiaPlaza.Control.Vue.Notifications.Shared.INotification
	{
		Template: 'MemberPenalty';
		Portal: MiaPlaza.Model.PortalType;
		Reason: string;
		Until: string;
		PenaltyImage: Image;
	}
}
export namespace MiaPlaza.Navigation {
	export class VueRoutes
	{
		public static SHOP_ROUTE_KEY: string = `shop`;
		public static SHOP_TEMPLATE: string = `/shop/:shopType`;
		public static DESIGN_STUDIO: string = `/design-studio`;
		public static DESIGN_STUDIO_SHOP_PARAMETER: string = `PublicShopType`;
		public static MEMBER_SHOP: string = `/shop/child/:child`;
	}
}
export namespace MiaPlaza.Navigation.MenuBuilder {
	export abstract class Profile
	{
		public static END_FRIENDSHIP_BUTTON: string = `mia-js-endFriendshipButton`;
	}
}
export namespace MiaPlaza {
	export interface IPlaceholder
	{
		Placeholder: string;
	}
	export abstract class RoutedHelper
	{
		public static GetParameter(parameter: any) : string
		{
			return parameter?.Placeholder ?? encodeURIComponent(JSON.stringify(parameter));
		}
	}
}
export namespace MiaPlaza.LO {
	export abstract class GradeName
	{
		public static CARDINAL_PREFIX: string = `GradeCardinalName`;
		public static ORDINAL_PREFIX: string = `GradeOrdinalName`;
		public static VERBOSE_PREFIX: string = `GradeVerboseName`;
		public static VERBOSE_VOCABULARY_PREFIX: string = `GradeVerboseVocabularyName`;
	}
	export enum RequestStatus {
		AlreadyConnected = "AlreadyConnected",
		ConnectionSuccessful = "ConnectionSuccessful",
		AwaitingConfirm = "AwaitingConfirm",
		FriendshipcodeWrong = "FriendshipcodeWrong",
		RequesterIsTrial = "RequesterIsTrial",
		FriendIsTrial = "FriendIsTrial",
		RequesterIsUnverified = "RequesterIsUnverified",
		FriendIsUnverified = "FriendIsUnverified"
	}
	export enum CourseState {
		None = "None",
		Required = "Required",
		Locked = "Locked"
	}
	export abstract class GNOMImage
	{
		public static GetPetImageUrl(gnomObject: string | MiaPlaza.IPlaceholder) : string
		{
			return `/LO.GNOMImage/GetPetImage?gnomObject=${MiaPlaza.RoutedHelper.GetParameter(gnomObject)}&_=`;
		}
	}
}
export namespace MiaPlaza.LO.VirtualCurrency {
	export interface IUserPrize
	{
		DaysLeft: number;
		Image: Image | null;
	}
}
export namespace MiaPlaza.LO.Testimonials {
	export interface ITestimonial
	{
		Author: string;
		Highlight: string;
		Text: string;
	}
}
export namespace MiaPlaza.LO.Shoutboxes {
	export interface IShoutboxData
	{
		Shoutbox: string;
		SmileyList: string[];
		ShoutboxLineTextLimit: number;
		ChildMayPost: boolean;
		ChildMayPostErrorMessage: string;
	}
}
export namespace MiaPlaza.LO.PracticeAndAssessment {
	export interface IAssessment
	{
		Questions: MiaPlaza.LO.PracticeAndAssessment.Dtos.Questions.IAssessmentQuestion[];
	}
	export interface IPractice
	{
		Questions: MiaPlaza.LO.PracticeAndAssessment.Dtos.Questions.IPracticeQuestion[];
		CurrentLevel: number;
		MedalInformation: MiaPlaza.LO.PracticeAndAssessment.IPracticeMedal;
		LevelReferenceMaterials: { [key:number]: MiaPlaza.LO.PracticeAndAssessment.Dtos.ReferenceMaterials.IReferenceMaterial[] };
		NumberOfLevels: number;
		NumberOfQuestions: { [key:number]: number };
	}
	export interface IPracticeMedal
	{
		HighestStudentMedal: MiaPlaza.Model.Games.MedalType;
		NextMedalLevel: number;
		NextMedal: MiaPlaza.Model.Games.MedalType;
	}
}
export namespace MiaPlaza.LO.PracticeAndAssessment.Dtos {
	export interface IAssessmentReward
	{
		Reward: number;
		Passed: boolean;
		PassingScorePercentage: number | null;
	}
	export interface IPracticeReward
	{
		MedalInformation: MiaPlaza.LO.PracticeAndAssessment.IPracticeMedal;
		Reward: number;
		FutureReward: number;
	}
}
export namespace MiaPlaza.LO.PracticeAndAssessment.Dtos.ReferenceMaterials {
	export interface IReferenceMaterial
	{
		Id: number;
		ContentBlock: MiaPlaza.LO.PracticeAndAssessment.Dtos.ContentBlocks.IContentBlock;
	}
}
export namespace MiaPlaza.LO.PracticeAndAssessment.Dtos.Questions {
	export interface IAssessmentQuestion extends MiaPlaza.LO.PracticeAndAssessment.Dtos.Questions.IQuestion
	{
	}
	export interface IPracticeQuestion extends MiaPlaza.LO.PracticeAndAssessment.Dtos.Questions.IQuestion
	{
		MinLevel: number;
		MaxLevel: number;
	}
	export interface IQuestion
	{
		QuestionId: string;
		Prompt: MiaPlaza.LO.PracticeAndAssessment.Dtos.ContentBlocks.IContentBlock[];
		AnswerSection: MiaPlaza.LO.PracticeAndAssessment.Dtos.AnswerSections.IAnswerSection;
		ReferenceMaterials: MiaPlaza.LO.PracticeAndAssessment.Dtos.ReferenceMaterials.IReferenceMaterial[];
	}
}
export namespace MiaPlaza.LO.PracticeAndAssessment.Dtos.ContentBlocks {
	export interface IContentBlock
	{
		Type: MiaPlaza.LO.PracticeAndAssessment.Dtos.ContentBlocks.ContentBlockType;
	}
	export enum ContentBlockType {
		Text = "Text",
		Image = "Image",
		LaTeX = "LaTeX",
		HTML = "HTML"
	}
	export interface IHtmlBlock extends MiaPlaza.LO.PracticeAndAssessment.Dtos.ContentBlocks.ITtsBlock, MiaPlaza.LO.PracticeAndAssessment.Dtos.ContentBlocks.IContentBlock
	{
		Content: string;
		TtsUrl: string;
	}
	export interface IImageBlock extends MiaPlaza.LO.PracticeAndAssessment.Dtos.ContentBlocks.IContentBlock
	{
		Url: string;
	}
	export interface ITtsBlock
	{
		TtsUrl: string;
	}
	export interface ILaTeXBlock extends MiaPlaza.LO.PracticeAndAssessment.Dtos.ContentBlocks.ITtsBlock, MiaPlaza.LO.PracticeAndAssessment.Dtos.ContentBlocks.ITextBlock
	{
	}
	export interface ITextBlock extends MiaPlaza.LO.PracticeAndAssessment.Dtos.ContentBlocks.ITtsBlock, MiaPlaza.LO.PracticeAndAssessment.Dtos.ContentBlocks.IContentBlock
	{
		Content: string;
		TtsUrl: string;
	}
}
export namespace MiaPlaza.LO.PracticeAndAssessment.Dtos.AnswerSections {
	export interface IAnswerChoice
	{
		AnswerId: string;
		Correct: boolean;
		Content: MiaPlaza.LO.PracticeAndAssessment.Dtos.ContentBlocks.IContentBlock[];
	}
	export interface IAnswerSection
	{
		Type: MiaPlaza.LO.PracticeAndAssessment.Dtos.AnswerSections.AnswerSectionType;
	}
	export enum AnswerSectionType {
		FreeInputText = "FreeInputText",
		MultipleChoice = "MultipleChoice"
	}
	export interface IFreeInputTextAnswerSection extends MiaPlaza.LO.PracticeAndAssessment.Dtos.AnswerSections.IAnswerSection
	{
		CorrectAnswer: string;
	}
	export interface IMultipleChoiceAnswerSection extends MiaPlaza.LO.PracticeAndAssessment.Dtos.AnswerSections.IAnswerSection
	{
		AnswerChoices: MiaPlaza.LO.PracticeAndAssessment.Dtos.AnswerSections.IAnswerChoice[];
	}
}
export namespace MiaPlaza.LO.PracticeAndAssessment.Dtos.Answers {
	export interface IAnswer
	{
		Type: MiaPlaza.LO.PracticeAndAssessment.Dtos.AnswerSections.AnswerSectionType;
		Correct: boolean;
		QuestionId: string;
	}
	export interface IFreeInputAnswer extends MiaPlaza.LO.PracticeAndAssessment.Dtos.Answers.IAnswer
	{
		FreeInputAnswerValue: string;
	}
	export interface IMultipleChoiceAnswer extends MiaPlaza.LO.PracticeAndAssessment.Dtos.Answers.IAnswer
	{
		AnswerId: string;
	}
}
export namespace MiaPlaza.LO.Payments {
	export class BraintreeCheckout
	{
		private static productInformationService: any;
		private static captcha: any;
		public static CAPTCHA_ACTION: string = `CHECKOUT`;
		public static GetCheckoutUrl(paymentProcess: string | MiaPlaza.IPlaceholder, paymentMethodNonce: string | MiaPlaza.IPlaceholder, captchaToken: string | MiaPlaza.IPlaceholder) : string
		{
			return `/LO.Payments.BraintreeCheckout/Checkout?paymentProcess=${MiaPlaza.RoutedHelper.GetParameter(paymentProcess)}&paymentMethodNonce=${MiaPlaza.RoutedHelper.GetParameter(paymentMethodNonce)}&captchaToken=${MiaPlaza.RoutedHelper.GetParameter(captchaToken)}&_=`;
		}
	}
	export interface IPurchaseStatistics
	{
		PurchaseCount: MiaPlaza.LO.Payments.IPurchaseData[];
		FirstSubscriptionDate: string;
		LatestPurchaseDate: string;
	}
	export interface IPurchaseData
	{
		PaymentType: MiaPlaza.Model.Payments.ProductType;
		Quantity: number;
	}
	export abstract class BraintreeSynchronization
	{
		public static GetSynchronizeSubscriptionUrl(subscription: string | MiaPlaza.IPlaceholder) : string
		{
			return `/LO.Payments.BraintreeSynchronization/SynchronizeSubscription?subscription=${MiaPlaza.RoutedHelper.GetParameter(subscription)}&_=`;
		}
	}
}
export namespace MiaPlaza.LO.JSON {
	export interface IVideoModel
	{
	}
}
export namespace MiaPlaza.LO.JavaScript {
	export abstract class Log
	{
		public static ErrorLoggingUrl: string = `/LO.JavaScript.Log/Error?_=`;
		public static GetErrorUrl() : string
		{
			return "/LO.JavaScript.Log/Error?_=";
		}
	}
}
export namespace MiaPlaza.LO.Gradebook {
	export enum GradebookAccess {
		Admin = "Admin",
		Parent = "Parent",
		Child = "Child"
	}
	export interface IGradebookData
	{
		ChildUUId: string;
		ChildName: string;
		IsMohsStudent: boolean;
		IsActiveMohsStudent: boolean;
		IsGradebookAvailable: boolean;
		Access: MiaPlaza.LO.Gradebook.GradebookAccess;
	}
	export interface IGradebookAdminData extends MiaPlaza.LO.Gradebook.IGradebookData
	{
		JoinedDate: string;
		LeftDate: string | null;
		ParentName: string;
		ParentUrl: string;
		ChildImpersonationUrl: string | null;
		NumberOfUngradedCheckpoints: number;
		LastLogin: string;
		SapLink: string | null;
	}
}
export namespace MiaPlaza.LO.Games {
	export interface IAudioSettings
	{
		FeedbackSoundAvailableInGame: boolean;
		FeedbackSoundEnabled: boolean;
		TextToSpeechAvailableInGame: boolean;
		TextToSpeechEnabledByParent: boolean;
		TextToSpeechEnabled: boolean;
	}
	export interface IDragonMove
	{
	}
	export abstract class MultiplayerGame
	{
		public static GetAcceptInvitationUrl(host: string | MiaPlaza.IPlaceholder, instance: string | MiaPlaza.IPlaceholder) : string
		{
			return `/LO.Games.MultiplayerGame/AcceptInvitation?host=${MiaPlaza.RoutedHelper.GetParameter(host)}&instance=${MiaPlaza.RoutedHelper.GetParameter(instance)}&_=`;
		}
		public static GetDeclineInvitationUrl(host: string | MiaPlaza.IPlaceholder, instance: string | MiaPlaza.IPlaceholder, noInvitationsUntil: string | MiaPlaza.IPlaceholder) : string
		{
			return `/LO.Games.MultiplayerGame/DeclineInvitation?host=${MiaPlaza.RoutedHelper.GetParameter(host)}&instance=${MiaPlaza.RoutedHelper.GetParameter(instance)}&noInvitationsUntil=${MiaPlaza.RoutedHelper.GetParameter(noInvitationsUntil)}&_=`;
		}
	}
}
export namespace MiaPlaza.LO.Games.Quizzes {
	export interface IAssessmentAnswerResult
	{
		AssessmentScoreContent: string[];
		QuestionsLeft: number;
		Progress: number;
		Amount: number;
		IsSolved: boolean;
	}
	export interface IMediaText
	{
		Text: string;
		ViewTimeSeconds: number;
		SecondViewTimeSeconds: number;
	}
	export interface INextMedal
	{
		ImageSource: string;
		Level: number;
		NoNextMedal: boolean;
	}
	export interface IPracticeAnswerResult
	{
		Level: number;
		Amount: number;
		IsLevelUp: boolean;
		NewReward: number;
		Medal: string;
		MedalName: string;
		Pet: string;
		PetName: string;
		ChallengeWasCompleted: boolean;
		OpenProdding: string;
		IsLastLevelCompleted: boolean;
	}
	export interface IQuestionData
	{
		QuizContent: string[];
		AnswerPaths: string[];
		Progress: number;
		QuestionsLeft: number;
		MediaText: MiaPlaza.LO.Games.Quizzes.IMediaText;
		Path: string;
		Id: string;
		SoundFile: string;
		NextMedalImageSource: string;
		NextMedalLevel: number;
		NoNextMedal: boolean;
		NextMedal: MiaPlaza.LO.Games.Quizzes.INextMedal;
	}
}
export namespace MiaPlaza.LO.Curriculum {
	export interface ICourseGrade
	{
		AssessmentAverage: number;
		CheckpointAverage: number;
		OverallScoreText: string;
	}
	export interface ICourseProgress
	{
		ActualProgress: number;
		ExpectedProgress: number;
		Behind: boolean;
	}
	export class UnitCatalog
	{
	}
}
export namespace MiaPlaza.LO.Curriculum.ScopeAndSequence {
	export interface IScopeAndSequenceData
	{
		Subject: MiaPlaza.Model.Subject;
		Courses: MiaPlaza.LO.Curriculum.ScopeAndSequence.ICourseData[];
	}
	export interface ICourseData
	{
		Name: string;
		RecommendedGradeMin: number;
		RecommendedGradeMax: number;
		Units: MiaPlaza.LO.Curriculum.ScopeAndSequence.IUnitData[];
		InDevelopment: boolean;
	}
	export interface IUnitData
	{
		Name: string;
		Lessons: string[];
	}
}
export namespace MiaPlaza.LO.Curriculum.Display {
	export interface ICourseReport
	{
		Title: string;
		Assessments: MiaPlaza.LO.Curriculum.Display.IAssessmentData[];
		StudyTime: string;
		Subject: MiaPlaza.Model.Subject;
		Course: string;
		OverallScore: string;
		SelectedDatesScore: string;
	}
	export interface IAssessmentData
	{
		Name: string;
		Progress: string;
		DateTime: string;
		Date: string;
		DetailsLink: string;
		IncludeInPrintableReport: boolean;
		UUId: string;
		CheckpointAssessment: boolean;
		IsExcused: boolean;
	}
	export interface ILessonData
	{
		Title: string;
		isAssignmentMode: boolean;
		IsExcused: boolean;
		Intro: string;
		LearningPathLink: string;
		GoogleClassroomLink: string;
		SelectedFlavor: MiaPlaza.Model.Curriculum.Task.Flavor;
	}
	export interface ITaskAssignmentData
	{
		Name: string;
		Type: MiaPlaza.Model.Curriculum.Task.TaskType;
		Status: MiaPlaza.Controllers.Curriculum.Enums.ProgressStatus;
		Image: Image;
		Icon: Image;
		PreviewData: MiaPlaza.LO.Curriculum.Display.IPreviewData;
		CheckpointAssessment: boolean;
	}
	export interface IPreviewData
	{
		Title: string;
	}
	export interface IRedirectPreviewData extends MiaPlaza.LO.Curriculum.Display.IPreviewData
	{
		Url: string;
	}
	export interface ILightboxPreviewData extends MiaPlaza.LO.Curriculum.Display.IPreviewData
	{
		Url: string;
	}
	export interface ILessonInfoPreviewData extends MiaPlaza.LO.Curriculum.Display.IPreviewData
	{
		ExplanationParents: string;
	}
	export interface ISupplementalPreviewData extends MiaPlaza.LO.Curriculum.Display.IPreviewData
	{
		Url: string;
	}
	export enum LightboxMode {
		Default = "Default",
		ElevenByNine = "ElevenByNine",
		FullScreen = "FullScreen"
	}
}
export namespace MiaPlaza.LO.Curriculum.Configuration {
	export interface IEditCourseEnrollmentData
	{
		CourseEnrollment: string;
		Subject: MiaPlaza.Model.Subject;
		Course: string;
		CourseTitle: string;
		CourseDescription: string;
		Start: string;
		End: string;
		SchoolWeek: string[];
		IsRequired: boolean;
		IsFreeInputPreferable: boolean;
		PassingScorePercentage: number | null;
		SuggestedTasksCountPerDayForInDevelopmentCourse: number | null;
	}
	export enum CourseEnrollmentStatus {
		InProgress = "InProgress",
		NoTasks = "NoTasks",
		CompletedAndNoTasksAssigned = "CompletedAndNoTasksAssigned"
	}
	export interface ICurriculumData
	{
		Child: string;
		ChildName: string;
		ExcludedSubject: MiaPlaza.Model.Subject | null;
		VocabularyGrade: number | null;
		VocabularyGrades: number[];
		FirstAssignedDate: string | null;
		DailyVocabularyPracticeEnabled: boolean;
		DailyVocabularyPracticeConsentRequired: boolean;
		ShowPublicHolidays: boolean;
		ShowMoreCoreUnitsSoonPrompt: boolean;
		ReadOnlyView: boolean;
		ScopeAndSequenceLinks: { [key:string]: string };
		IsActiveMohsStudent: boolean;
	}
}
export namespace MiaPlaza.HttpPipeline {
	export interface IFile
	{
	}
}
export namespace MiaPlaza.HttpPipeline.Middlewares {
	export class RoutingMiddleware
	{
		public static MangledArgumentRegex: {Pattern:string, Options:number};
		public static POST_DATA_MARKER: string = `__SEE_POST_DATA__`;
		public static MANGLED_ARGUMENT: string = `_=`;
	}
}
export namespace MiaPlaza.Controllers.RankedList {
	export interface IListItem
	{
		MemberName: string;
		MemberUrl: string;
		IsCurrentMember: boolean;
		Value: string;
	}
	export interface IListItemWithRank extends MiaPlaza.Controllers.RankedList.IListItem
	{
		Rank: number;
	}
}
export namespace MiaPlaza.Controllers.Payments {
	export class BraintreeController
	{
		public static CAPTCHA_ACTION_VAULT_PAYMENT_METHOD: string = `VAULT_PAYMENT_METHOD`;
		public static CAPTCHA_ACTION_UPDATE_PAYMENT_METHOD: string = `UPDATE_PAYMENT_METHOD`;
		public static VERIFY_CARDS_IN_BACKEND: boolean = true;
		private static captcha: any;
		public static GetVaultPaymentMethodUrl(paymentMethodNonce: string | MiaPlaza.IPlaceholder, captchaToken: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Payments.BraintreeController/VaultPaymentMethod?paymentMethodNonce=${MiaPlaza.RoutedHelper.GetParameter(paymentMethodNonce)}&captchaToken=${MiaPlaza.RoutedHelper.GetParameter(captchaToken)}&_=`;
		}
		public static GetUpdatePaymentMethodUrl(subscription: string | MiaPlaza.IPlaceholder, paymentMethodNonce: string | MiaPlaza.IPlaceholder, captchaToken: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Payments.BraintreeController/UpdatePaymentMethod?subscription=${MiaPlaza.RoutedHelper.GetParameter(subscription)}&paymentMethodNonce=${MiaPlaza.RoutedHelper.GetParameter(paymentMethodNonce)}&captchaToken=${MiaPlaza.RoutedHelper.GetParameter(captchaToken)}&_=`;
		}
	}
}
export namespace MiaPlaza.Controllers.Curriculum.Models {
	export interface IAssignableContentData
	{
		IsAssigned: boolean;
		IsCompleted: boolean;
		IsExcused: boolean;
	}
	export interface IExcuseResult
	{
		ErrorMessage: string;
		Success: boolean;
	}
}
export namespace MiaPlaza.Controllers.Curriculum.Enums {
	export enum ProgressStatus {
		None = "None",
		Completed = "Completed"
	}
}
export namespace MiaPlaza.Controllers.Admin {
	export interface ISpecialUserData
	{
		ProfileLink: string;
		FirstAndLastName: string;
		SpecialUserType: MiaPlaza.Model.SpecialUserType;
		EMail: string;
	}
	export interface ICustomGameVersion
	{
		Number: number;
		Text: string;
	}
	export interface ICustomGame
	{
		CommonIdentifier: string;
		LocalVersion: MiaPlaza.Controllers.Admin.ICustomGameVersion;
		RemoteVersion: MiaPlaza.Controllers.Admin.ICustomGameVersion;
		IsInstalled: boolean;
		LessonLink: string;
		PracticeLink: string;
		AssessmentLink: string;
	}
	export interface IBuyerData
	{
		Id: number;
		AccountLink: string;
		Firstname: string;
		Lastname: string;
		EMail: string;
		NumberOfChildren: number;
		ChildrenLogins: number;
		PurchaseStatistics: MiaPlaza.LO.Payments.IPurchaseStatistics;
	}
	export interface IActiveModerator
	{
		Moderator: string;
		Name: string;
		NumberOfLocks: number;
	}
	export interface IAdminProductPrice
	{
		FirstPeriodPrice: number;
		Price: number;
		Start: string;
		End: string;
		Product: string;
	}
	export interface ITopEarner
	{
		Child: string;
		ChildName: string;
		ChildProfileLink: string;
		VirtualMoney: number;
		EarningsOverPeriod: number;
		CheaterStatus: MiaPlaza.Model.CheaterStatus;
	}
	export interface IVirtualCurrencyTransaction
	{
		DateTime: string;
		Delta: number;
		PreviousAmount: number;
		Type: string;
		Reason: string;
	}
	export interface ICompetitionWinner
	{
		Name: string;
		ProfileLink: string;
		CompetitionDate: string;
		CompetitionType: MiaPlaza.Model.CompetitionType;
		MoneyEarned: number;
		PrizeName: string;
	}
	export class CurriculumController
	{
		public static GetAssignAllTasksUrl(child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.CurriculumController/AssignAllTasks?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
	}
	export class CustomGameController
	{
		public static GetConvertUrl(commonIdentifier: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.CustomGameController/Convert?commonIdentifier=${MiaPlaza.RoutedHelper.GetParameter(commonIdentifier)}&_=`;
		}
		public static GetUpdateUrl(commonIdentifier: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.CustomGameController/Update?commonIdentifier=${MiaPlaza.RoutedHelper.GetParameter(commonIdentifier)}&_=`;
		}
		public static GetInstalledGamesUrl(identifier: string | MiaPlaza.IPlaceholder, upToDate: boolean | MiaPlaza.IPlaceholder, isActive: boolean | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.CustomGameController/GetInstalledGames?identifier=${MiaPlaza.RoutedHelper.GetParameter(identifier)}&upToDate=${MiaPlaza.RoutedHelper.GetParameter(upToDate)}&isActive=${MiaPlaza.RoutedHelper.GetParameter(isActive)}&_=`;
		}
		public static GetNotInstalledGamesUrl(identifier: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.CustomGameController/GetNotInstalledGames?identifier=${MiaPlaza.RoutedHelper.GetParameter(identifier)}&_=`;
		}
	}
	export class DownloadEmailsController
	{
		public static GetBuyersUrl(paymentTypes: MiaPlaza.Model.Payments.ProductType[] | MiaPlaza.IPlaceholder, startTime: string | MiaPlaza.IPlaceholder, endTime: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.DownloadEmailsController/GetBuyers?paymentTypes=${MiaPlaza.RoutedHelper.GetParameter(paymentTypes)}&startTime=${MiaPlaza.RoutedHelper.GetParameter(startTime)}&endTime=${MiaPlaza.RoutedHelper.GetParameter(endTime)}&_=`;
		}
		public static GetConvertBuyerUrl(buyer: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.DownloadEmailsController/ConvertBuyer?buyer=${MiaPlaza.RoutedHelper.GetParameter(buyer)}&_=`;
		}
	}
	export abstract class ImpersonationController
	{
		public static GetImpersonateUrl(target: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.ImpersonationController/Impersonate?target=${MiaPlaza.RoutedHelper.GetParameter(target)}&_=`;
		}
	}
	export class MemberController
	{
		public static GetIsMemberOnlineUrl(member: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.MemberController/IsMemberOnline?member=${MiaPlaza.RoutedHelper.GetParameter(member)}&_=`;
		}
	}
	export class ModerationController
	{
		public static GetActiveModeratorsUrl() : string
		{
			return "/Controllers.Admin.ModerationController/GetActiveModerators?_=";
		}
		public static GetReleaseModeratorLocksUrl(moderator: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.ModerationController/ReleaseModeratorLocks?moderator=${MiaPlaza.RoutedHelper.GetParameter(moderator)}&_=`;
		}
		public static GetConvertModeratorUrl(member: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.ModerationController/ConvertModerator?member=${MiaPlaza.RoutedHelper.GetParameter(member)}&_=`;
		}
	}
	export class PaymentController
	{
		public static GetCancelPaymentUrl(payment: string | MiaPlaza.IPlaceholder, day: number | MiaPlaza.IPlaceholder, month: number | MiaPlaza.IPlaceholder, year: number | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.PaymentController/CancelPayment?payment=${MiaPlaza.RoutedHelper.GetParameter(payment)}&day=${MiaPlaza.RoutedHelper.GetParameter(day)}&month=${MiaPlaza.RoutedHelper.GetParameter(month)}&year=${MiaPlaza.RoutedHelper.GetParameter(year)}&_=`;
		}
		public static GetCancelPaymentDirectlyUrl(payment: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.PaymentController/CancelPaymentDirectly?payment=${MiaPlaza.RoutedHelper.GetParameter(payment)}&_=`;
		}
		public static GetVoidPaymentUrl(payment: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.PaymentController/VoidPayment?payment=${MiaPlaza.RoutedHelper.GetParameter(payment)}&_=`;
		}
	}
	export class PricingController
	{
		public static GetConvertUrl(productPrice: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.PricingController/Convert?productPrice=${MiaPlaza.RoutedHelper.GetParameter(productPrice)}&_=`;
		}
		public static GetSubscriptionPricesUrl() : string
		{
			return "/Controllers.Admin.PricingController/GetSubscriptionPrices?_=";
		}
		public static GetSalePricesUrl(product: MiaPlaza.Model.Payments.ProductType | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.PricingController/GetSalePrices?product=${MiaPlaza.RoutedHelper.GetParameter(product)}&_=`;
		}
		public static GetCreateSubscriptionPriceUrl(start: string | MiaPlaza.IPlaceholder, firstPeriodPrice: number | MiaPlaza.IPlaceholder, price: number | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.PricingController/CreateSubscriptionPrice?start=${MiaPlaza.RoutedHelper.GetParameter(start)}&firstPeriodPrice=${MiaPlaza.RoutedHelper.GetParameter(firstPeriodPrice)}&price=${MiaPlaza.RoutedHelper.GetParameter(price)}&_=`;
		}
		public static GetCreateSalePriceUrl(product: MiaPlaza.Model.Payments.ProductType | MiaPlaza.IPlaceholder, start: string | MiaPlaza.IPlaceholder, end: string | MiaPlaza.IPlaceholder, price: number | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.PricingController/CreateSalePrice?product=${MiaPlaza.RoutedHelper.GetParameter(product)}&start=${MiaPlaza.RoutedHelper.GetParameter(start)}&end=${MiaPlaza.RoutedHelper.GetParameter(end)}&price=${MiaPlaza.RoutedHelper.GetParameter(price)}&_=`;
		}
	}
	export class SiteSlowWarningController
	{
		public static GetSetWarningTypeUrl(warningType: MiaPlaza.Control.Vue.Notifications.WarningType | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.SiteSlowWarningController/SetWarningType?warningType=${MiaPlaza.RoutedHelper.GetParameter(warningType)}&_=`;
		}
	}
	export class SpecialUsersController
	{
		public static GetSpecialUsersUrl(search: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.SpecialUsersController/GetSpecialUsers?search=${MiaPlaza.RoutedHelper.GetParameter(search)}&_=`;
		}
		public static GetConvertSpecialUsersUrl(member: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.SpecialUsersController/ConvertSpecialUsers?member=${MiaPlaza.RoutedHelper.GetParameter(member)}&_=`;
		}
		public static GetAddSpecialUserRightsUrl(memberId: number | MiaPlaza.IPlaceholder, specialUserType: MiaPlaza.Model.SpecialUserType | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.SpecialUsersController/AddSpecialUserRights?memberId=${MiaPlaza.RoutedHelper.GetParameter(memberId)}&specialUserType=${MiaPlaza.RoutedHelper.GetParameter(specialUserType)}&_=`;
		}
		public static GetRemoveSpecialUserRightsUrl(member: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.SpecialUsersController/RemoveSpecialUserRights?member=${MiaPlaza.RoutedHelper.GetParameter(member)}&_=`;
		}
	}
	export class VirtualCurrencyController
	{
		public static GetGiveMoneyPresetUrl(type: MiaPlaza.Model.VirtualCurrency.Transaction.PresetTransactionType | MiaPlaza.IPlaceholder, child: string | MiaPlaza.IPlaceholder, amount: number | MiaPlaza.IPlaceholder, countsAsEarned: boolean | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.VirtualCurrencyController/GiveMoneyPreset?type=${MiaPlaza.RoutedHelper.GetParameter(type)}&child=${MiaPlaza.RoutedHelper.GetParameter(child)}&amount=${MiaPlaza.RoutedHelper.GetParameter(amount)}&countsAsEarned=${MiaPlaza.RoutedHelper.GetParameter(countsAsEarned)}&_=`;
		}
		public static GetTopEarnersUrl(numberOfMembers: number | MiaPlaza.IPlaceholder, start: string | MiaPlaza.IPlaceholder, end: string | MiaPlaza.IPlaceholder, cheaterStatus?: MiaPlaza.Model.CheaterStatus | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.VirtualCurrencyController/GetTopEarners?numberOfMembers=${MiaPlaza.RoutedHelper.GetParameter(numberOfMembers)}&start=${MiaPlaza.RoutedHelper.GetParameter(start)}&end=${MiaPlaza.RoutedHelper.GetParameter(end)}&cheaterStatus=${MiaPlaza.RoutedHelper.GetParameter(cheaterStatus)}&_=`;
		}
		public static GetSetCheaterStatusUrl(member: string | MiaPlaza.IPlaceholder, cheaterStatus: MiaPlaza.Model.CheaterStatus | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.VirtualCurrencyController/SetCheaterStatus?member=${MiaPlaza.RoutedHelper.GetParameter(member)}&cheaterStatus=${MiaPlaza.RoutedHelper.GetParameter(cheaterStatus)}&_=`;
		}
		public static GetVirtualCurrencyTransactionsUrl(member: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.VirtualCurrencyController/GetVirtualCurrencyTransactions?member=${MiaPlaza.RoutedHelper.GetParameter(member)}&_=`;
		}
		public static GetConvertTransactionUrl(transaction: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.VirtualCurrencyController/ConvertTransaction?transaction=${MiaPlaza.RoutedHelper.GetParameter(transaction)}&_=`;
		}
		public static GetConvertTopEarnerUrl(member: string | MiaPlaza.IPlaceholder, start: string | MiaPlaza.IPlaceholder, end: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.VirtualCurrencyController/ConvertTopEarner?member=${MiaPlaza.RoutedHelper.GetParameter(member)}&start=${MiaPlaza.RoutedHelper.GetParameter(start)}&end=${MiaPlaza.RoutedHelper.GetParameter(end)}&_=`;
		}
		public static GetConvertCompetitionWinnerUrl(winner: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.VirtualCurrencyController/ConvertCompetitionWinner?winner=${MiaPlaza.RoutedHelper.GetParameter(winner)}&_=`;
		}
		public static GetCompetitionWinnersUrl(winnersCount: number | MiaPlaza.IPlaceholder, start: string | MiaPlaza.IPlaceholder, end: string | MiaPlaza.IPlaceholder, competitionType?: MiaPlaza.Model.CompetitionType | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.VirtualCurrencyController/GetCompetitionWinners?winnersCount=${MiaPlaza.RoutedHelper.GetParameter(winnersCount)}&start=${MiaPlaza.RoutedHelper.GetParameter(start)}&end=${MiaPlaza.RoutedHelper.GetParameter(end)}&competitionType=${MiaPlaza.RoutedHelper.GetParameter(competitionType)}&_=`;
		}
	}
}
export namespace MiaPlaza.Controllers.Admin.Mohs {
	export interface IChildData
	{
		Name: string;
		Id: number;
	}
	export interface IParentData
	{
		Name: string;
		Url: string;
	}
	export interface IMohsStudent
	{
		ChildData: MiaPlaza.Controllers.Admin.Mohs.IChildData;
		ParentData: MiaPlaza.Controllers.Admin.Mohs.IParentData;
		NumberOfUngradedCheckpoints: number;
		LastLogin: string;
		SapLink: string;
		IsActive: boolean;
	}
	export class MohsController
	{
		public static GetAddStudentUrl(childId: number | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.Mohs.MohsController/AddStudent?childId=${MiaPlaza.RoutedHelper.GetParameter(childId)}&_=`;
		}
		public static GetConvertStudentUrl(student: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.Mohs.MohsController/ConvertStudent?student=${MiaPlaza.RoutedHelper.GetParameter(student)}&_=`;
		}
		public static GetStudentsUrl() : string
		{
			return "/Controllers.Admin.Mohs.MohsController/GetStudents?_=";
		}
		public static GetSetSapLinkUrl(student: string | MiaPlaza.IPlaceholder, sapLink: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.Mohs.MohsController/SetSapLink?student=${MiaPlaza.RoutedHelper.GetParameter(student)}&sapLink=${MiaPlaza.RoutedHelper.GetParameter(sapLink)}&_=`;
		}
		public static GetActivateMohsStudentUrl(student: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.Mohs.MohsController/ActivateMohsStudent?student=${MiaPlaza.RoutedHelper.GetParameter(student)}&_=`;
		}
		public static GetDeactivateMohsStudentUrl(student: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.Mohs.MohsController/DeactivateMohsStudent?student=${MiaPlaza.RoutedHelper.GetParameter(student)}&_=`;
		}
		public static GetSetJoinedDateUrl(student: string | MiaPlaza.IPlaceholder, joinedDate: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.Mohs.MohsController/SetJoinedDate?student=${MiaPlaza.RoutedHelper.GetParameter(student)}&joinedDate=${MiaPlaza.RoutedHelper.GetParameter(joinedDate)}&_=`;
		}
		public static GetSetLeftDateUrl(student: string | MiaPlaza.IPlaceholder, leftDate: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.Mohs.MohsController/SetLeftDate?student=${MiaPlaza.RoutedHelper.GetParameter(student)}&leftDate=${MiaPlaza.RoutedHelper.GetParameter(leftDate)}&_=`;
		}
		public static GetRenderCSVReportUrl() : string
		{
			return "/Controllers.Admin.Mohs.MohsController/RenderCSVReport?_=";
		}
		public static GetMohsGradebookDataUrl(student: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.Mohs.MohsController/GetMohsGradebookData?student=${MiaPlaza.RoutedHelper.GetParameter(student)}&_=`;
		}
		public static GetMohsCurriculumDataUrl(student: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.Mohs.MohsController/GetMohsCurriculumData?student=${MiaPlaza.RoutedHelper.GetParameter(student)}&_=`;
		}
	}
}
export namespace MiaPlaza.Control.Vue {
	export class I18Next
	{
		public static Default: string = `en-US`;
		public static US: string = `en-US`;
		public static UK: string = `en-GB`;
		public static C: string = `C`;
		public static MODULE: string = `I18Next`;
		public static GLOSSARY: string = `glossary`;
		public static I18NEXT_KEY: string = `⸤…⸣`;
		public static GetConvertUrl(cultures: string[] | MiaPlaza.IPlaceholder, keys: string[] | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.I18Next/Convert?cultures=${MiaPlaza.RoutedHelper.GetParameter(cultures)}&keys=${MiaPlaza.RoutedHelper.GetParameter(keys)}&_=`;
		}
	}
	export interface IUserData
	{
		FirstName: string;
		LastName: string;
		Email: string;
	}
	export interface IProfileData
	{
		Name: string;
		Avatar: Image;
		ProfileUrl: string;
		FundsAmount: number;
		FundsPageUrl: string;
		NewGuestBookEntries: number;
	}
	export interface IPublicHolidayData
	{
		PublicHoliday: MiaPlaza.Model.PublicHoliday;
		Observed: boolean;
	}
	export interface IMyWorldActivity
	{
		Name: string;
		Image: Image;
		Target: string;
	}
	export enum RulesType {
		DesignStudio = 0,
		Community = 1
	}
	export interface ITeamMember
	{
		Title: string;
		About: string;
		PictureUrl: string;
	}
	export interface IVueGameData
	{
		CommonIdentifier: string;
		RawGameData: string;
		ProgressUrl: string;
		RedirectUrl: string;
		CurrentLevel: number;
		InitialMedalPicture: Image;
		IsIncludedInCurriculum: boolean;
	}
	export interface IMedalInformation
	{
		MedalPicture: Image;
	}
	export class ModeratorsNote
	{
		public static GetSaveMemberNoteUrl(member: string | MiaPlaza.IPlaceholder, note: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.ModeratorsNote/SaveMemberNote?member=${MiaPlaza.RoutedHelper.GetParameter(member)}&note=${MiaPlaza.RoutedHelper.GetParameter(note)}&_=`;
		}
	}
	export class VueGame
	{
		public static GetVueGameDataUrl(game: string | MiaPlaza.IPlaceholder, member: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.VueGame/GetVueGameData?game=${MiaPlaza.RoutedHelper.GetParameter(game)}&member=${MiaPlaza.RoutedHelper.GetParameter(member)}&_=`;
		}
		public static GetReportProgressUrl(member: string | MiaPlaza.IPlaceholder, commonIdentifier: string | MiaPlaza.IPlaceholder, level: number | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.VueGame/ReportProgress?member=${MiaPlaza.RoutedHelper.GetParameter(member)}&commonIdentifier=${MiaPlaza.RoutedHelper.GetParameter(commonIdentifier)}&level=${MiaPlaza.RoutedHelper.GetParameter(level)}&_=`;
		}
	}
}
export namespace MiaPlaza.Control.Vue.Vuex {
	export class FriendshipModule
	{
		public static MODULE: string = `friendship`;
		public static GetConvertUrl(other: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Vuex.FriendshipModule/Convert?other=${MiaPlaza.RoutedHelper.GetParameter(other)}&_=`;
		}
		public static GetRequestFriendshipUrl(other: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Vuex.FriendshipModule/RequestFriendship?other=${MiaPlaza.RoutedHelper.GetParameter(other)}&_=`;
		}
	}
	export interface IFriendship
	{
		IsFriend: boolean;
		CanBecomeFriend: boolean;
		IsPending: boolean;
		RequestRegularFriendUrl: string;
	}
}
export namespace MiaPlaza.Control.Vue.Vuex.Shared {
	export abstract class AjaxHelpers
	{
		public static PLACEHOLDER_CONVERT_ONE: string = `PLH_t_`;
		public static PLACEHOLDER_CONVERT_MANY: string = `PLH_ts_`;
		public static PLACEHOLDER_LIMIT: string = `PLH_limit_`;
		public static PLACEHOLDER_OFFSET: string = `PLH_offset_`;
		public static GetRangeAsyncUrl(dataUrl: string | MiaPlaza.IPlaceholder, offset: number | MiaPlaza.IPlaceholder, limit: number | MiaPlaza.IPlaceholder, offsetPrecision: MiaPlaza.Collections.Multitude.OffsetPrecision | MiaPlaza.IPlaceholder = MiaPlaza.Collections.Multitude.OffsetPrecision.Unfiltered, limitPrecision: MiaPlaza.Collections.Multitude.LimitPrecision | MiaPlaza.IPlaceholder = MiaPlaza.Collections.Multitude.LimitPrecision.AlmostExact) : string
		{
			return `/Control.Vue.Vuex.Shared.AjaxHelpers/GetRangeAsync?dataUrl=${MiaPlaza.RoutedHelper.GetParameter(dataUrl)}&offset=${MiaPlaza.RoutedHelper.GetParameter(offset)}&limit=${MiaPlaza.RoutedHelper.GetParameter(limit)}&offsetPrecision=${MiaPlaza.RoutedHelper.GetParameter(offsetPrecision)}&limitPrecision=${MiaPlaza.RoutedHelper.GetParameter(limitPrecision)}&_=`;
		}
		public static GetConvertAsyncUrl(data: string | MiaPlaza.IPlaceholder, converterUrl: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Vuex.Shared.AjaxHelpers/ConvertAsync?data=${MiaPlaza.RoutedHelper.GetParameter(data)}&converterUrl=${MiaPlaza.RoutedHelper.GetParameter(converterUrl)}&_=`;
		}
	}
	export interface IRangeWithOffset<S>
	{
		Items: S[];
		UnfilteredOffset: number;
		UnfilteredLimit: number;
		TotalCountEstimate: number;
	}
}
export namespace MiaPlaza.Control.Vue.Toggleable {
	export interface IKeyReplacementPair
	{
		Key: string;
		Replacements: any;
	}
}
export namespace MiaPlaza.Control.Vue.Notifications {
	export interface IBanner extends MiaPlaza.Control.Vue.Notifications.Shared.INotification
	{
		Template: 'Banner';
		Message: string;
		LinkText: string;
		LinkUrl: string;
		Deadline: string;
		SnoozeUrl: string;
		BannerImage: Image;
	}
	export interface IChallengeSolved extends MiaPlaza.Control.Vue.Notifications.Shared.INotification
	{
		Template: 'ChallengeSolved';
		LearningPathUrl: string;
	}
	export interface ICurriculumInitialized extends MiaPlaza.Control.Vue.Notifications.Shared.INotification
	{
		Template: 'CurriculumInitialized';
		FirstInitialization: boolean;
	}
	export interface IFullStoryConsent extends MiaPlaza.Control.Vue.Notifications.Shared.INotification
	{
		Template: 'FullStoryConsent';
		SitePrivacyLink: string;
	}
	export interface IIntroductionTutorialVideos extends MiaPlaza.Control.Vue.Notifications.Shared.INotification
	{
		Template: 'IntroductionTutorialVideos';
	}
	export interface IMemberPenalty extends MiaPlaza.Control.Vue.Notifications.Shared.INotification
	{
		Template: 'MemberPenalty';
		SnoozeForHours: number;
		Portal: MiaPlaza.Model.PortalType;
		Reason: string;
		Until: string;
		PenaltyImage: Image;
	}
	export interface IMiaPrepAnnouncement extends MiaPlaza.Control.Vue.Notifications.Shared.INotification
	{
		Template: 'MiaPrepAnnouncement';
	}
	export interface IMultiplayerGameInvitation extends MiaPlaza.Control.Vue.Notifications.Shared.INotification
	{
		Template: 'MultiplayerGameInvitation';
		HostName: string;
		GameName: string;
		AcceptLink: string;
		DeclineLink: string;
		DeclineWeekLink: string;
	}
	export interface ISessionMessage extends MiaPlaza.Control.Vue.Notifications.Shared.INotification
	{
		Template: 'SessionMessage';
		Type: MiaPlaza.Control.Vue.Notifications.SessionMessageType;
		Message: string;
	}
	export interface ISiteSlowWarning extends MiaPlaza.Control.Vue.Notifications.Shared.INotification
	{
		Template: 'SiteSlowWarning';
		uuid: string;
		SiteSlowWarningState: [MiaPlaza.Control.Vue.Notifications.WarningType,string];
	}
	export interface IValidationError extends MiaPlaza.Control.Vue.Notifications.Shared.INotification
	{
		Template: 'ValidationError';
		AffectedControls: string[];
		Message: string;
	}
	export enum SessionMessageType {
		Error = "Error",
		Warning = "Warning",
		Info = "Info",
		Success = "Success"
	}
	export enum WarningType {
		None = 0,
		ChildPortal = 1,
		ParentPortal = 2,
		Unauthorized = 4,
		All = 7
	}
}
export namespace MiaPlaza.Control.Vue.Notifications.Shared {
	export interface INotification
	{
		Template: string;
		Lifetime: MiaPlaza.Model.Lifetime;
		UniqueIdentifier: string;
		DismissUrl?: string;
	}
	export class PendingNotifications
	{
		public static GetDismissUrl(uniqueIdentifier: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Notifications.Shared.PendingNotifications/Dismiss?uniqueIdentifier=${MiaPlaza.RoutedHelper.GetParameter(uniqueIdentifier)}&_=`;
		}
	}
}
export namespace MiaPlaza.Control.Vue.Notifications.Aki {
	export interface IAkiMessage extends MiaPlaza.Control.Vue.Notifications.Shared.INotification
	{
		Template: 'AkiMessage';
		akiMessageOptions: any;
		LinkText: string;
		LinkUrl: string;
		HasAdditionalCloseButton: boolean;
		Message: string;
		Avatar: Image;
		IsShownImmediately: boolean;
	}
}
export namespace MiaPlaza.Control.Vue.Lists {
	export class AdminBozoList
	{
		public static PAGE_SIZE: number = 40;
		public static GetConvertUrl(member: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.AdminBozoList/Convert?member=${MiaPlaza.RoutedHelper.GetParameter(member)}&_=`;
		}
		public static GetRemoveBozoFlagUrl(bozo: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.AdminBozoList/RemoveBozoFlag?bozo=${MiaPlaza.RoutedHelper.GetParameter(bozo)}&_=`;
		}
	}
	export class AdminCouponCodeList
	{
		public static PAGE_SIZE: number = 30;
		public static GetConvertUrl(couponCode: string | MiaPlaza.IPlaceholder, selectedItemModule: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.AdminCouponCodeList/Convert?couponCode=${MiaPlaza.RoutedHelper.GetParameter(couponCode)}&selectedItemModule=${MiaPlaza.RoutedHelper.GetParameter(selectedItemModule)}&_=`;
		}
		public static GetDeleteUrl(couponCode: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.AdminCouponCodeList/Delete?couponCode=${MiaPlaza.RoutedHelper.GetParameter(couponCode)}&_=`;
		}
	}
	export class AdminEmbeddedSubscriptionList
	{
		public static PAGE_SIZE: number = 5;
		public static GetConvertUrl(subscription: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.AdminEmbeddedSubscriptionList/Convert?subscription=${MiaPlaza.RoutedHelper.GetParameter(subscription)}&_=`;
		}
		public static GetSubscriptionsUrl(child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.AdminEmbeddedSubscriptionList/GetSubscriptions?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
		public static GetUnpauseSubscriptionUrl(subscription: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.AdminEmbeddedSubscriptionList/UnpauseSubscription?subscription=${MiaPlaza.RoutedHelper.GetParameter(subscription)}&_=`;
		}
	}
	export class AdminLostPetList
	{
		public static PAGE_SIZE: number = 20;
		public static GetConvertUrl(gnomObject: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.AdminLostPetList/Convert?gnomObject=${MiaPlaza.RoutedHelper.GetParameter(gnomObject)}&_=`;
		}
		public static GetGiveGNOMBackUrl(gnomObject: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.AdminLostPetList/GiveGNOMBack?gnomObject=${MiaPlaza.RoutedHelper.GetParameter(gnomObject)}&_=`;
		}
	}
	export class AdminNewsletterContentList
	{
		public static PAGE_SIZE: number = 10;
		public static GetConvertUrl(entry: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.AdminNewsletterContentList/Convert?entry=${MiaPlaza.RoutedHelper.GetParameter(entry)}&_=`;
		}
		public static GetActionLinkUrl(entry: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.AdminNewsletterContentList/GetActionLink?entry=${MiaPlaza.RoutedHelper.GetParameter(entry)}&_=`;
		}
		public static GetActionByStatusUrl(currentStatus: MiaPlaza.Model.Newsletter.Status | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.AdminNewsletterContentList/GetActionByStatus?currentStatus=${MiaPlaza.RoutedHelper.GetParameter(currentStatus)}&_=`;
		}
		public static GetGenerateProgressUrl(entry: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.AdminNewsletterContentList/GenerateProgress?entry=${MiaPlaza.RoutedHelper.GetParameter(entry)}&_=`;
		}
		public static GetSendUrl(entry: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.AdminNewsletterContentList/Send?entry=${MiaPlaza.RoutedHelper.GetParameter(entry)}&_=`;
		}
		public static GetPauseUrl(entry: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.AdminNewsletterContentList/Pause?entry=${MiaPlaza.RoutedHelper.GetParameter(entry)}&_=`;
		}
		public static GetDeleteUrl(entry: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.AdminNewsletterContentList/Delete?entry=${MiaPlaza.RoutedHelper.GetParameter(entry)}&_=`;
		}
		public static GetDuplicateNewsletterUrl(newsletter: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.AdminNewsletterContentList/DuplicateNewsletter?newsletter=${MiaPlaza.RoutedHelper.GetParameter(newsletter)}&_=`;
		}
	}
	export class AdminTextResourceList
	{
		public static PAGE_SIZE: number = 20;
		public static GetReplaceUrl(key: string | MiaPlaza.IPlaceholder, data: MiaPlaza.Control.Vue.Lists.IAdminTextResourceData[] | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.AdminTextResourceList/Replace?key=${MiaPlaza.RoutedHelper.GetParameter(key)}&data=${MiaPlaza.RoutedHelper.GetParameter(data)}&_=`;
		}
		public static GetConvertUrl(key: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.AdminTextResourceList/Convert?key=${MiaPlaza.RoutedHelper.GetParameter(key)}&_=`;
		}
	}
	export class AdminUploadedImageList
	{
		public static PAGE_SIZE: number = 20;
		public static GetConvertUrl(image: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.AdminUploadedImageList/Convert?image=${MiaPlaza.RoutedHelper.GetParameter(image)}&_=`;
		}
		public static GetDeleteUrl(image: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.AdminUploadedImageList/Delete?image=${MiaPlaza.RoutedHelper.GetParameter(image)}&_=`;
		}
	}
	export class ArcadeGameList
	{
		public static PAGE_SIZE: number = 20;
		public static GetConvertUrl(game: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.ArcadeGameList/Convert?game=${MiaPlaza.RoutedHelper.GetParameter(game)}&_=`;
		}
	}
	export class ArticleList
	{
		public static PAGE_SIZE: number = 2;
		public static GetConvertUrl(article: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.ArticleList/Convert?article=${MiaPlaza.RoutedHelper.GetParameter(article)}&_=`;
		}
	}
	export class AssessmentQuestionList
	{
		public static PAGE_SIZE: number = 20;
		public static GetConvertUrl(question: string | MiaPlaza.IPlaceholder, workedOnAssessment: boolean | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.AssessmentQuestionList/Convert?question=${MiaPlaza.RoutedHelper.GetParameter(question)}&workedOnAssessment=${MiaPlaza.RoutedHelper.GetParameter(workedOnAssessment)}&_=`;
		}
	}
	export class DesignedItemList
	{
		public static PAGE_SIZE: number = 12;
		public static GetConvertUrl(entry: string | MiaPlaza.IPlaceholder, module: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.DesignedItemList/Convert?entry=${MiaPlaza.RoutedHelper.GetParameter(entry)}&module=${MiaPlaza.RoutedHelper.GetParameter(module)}&_=`;
		}
	}
	export class FriendRequestList
	{
		public static PAGE_SIZE: number = 10;
		public static GetConvertUrl(sender: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.FriendRequestList/Convert?sender=${MiaPlaza.RoutedHelper.GetParameter(sender)}&_=`;
		}
		public static GetAcceptFriendRequestUrl(member: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.FriendRequestList/AcceptFriendRequest?member=${MiaPlaza.RoutedHelper.GetParameter(member)}&_=`;
		}
		public static GetBlockFriendRequestUrl(member: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.FriendRequestList/BlockFriendRequest?member=${MiaPlaza.RoutedHelper.GetParameter(member)}&_=`;
		}
		public static GetDeclineFriendRequestUrl(sender: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.FriendRequestList/DeclineFriendRequest?sender=${MiaPlaza.RoutedHelper.GetParameter(sender)}&_=`;
		}
	}
	export class GiveGiftList
	{
		public static PAGE_SIZE: number = 12;
		public static GetConvertUrl(gift: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.GiveGiftList/Convert?gift=${MiaPlaza.RoutedHelper.GetParameter(gift)}&_=`;
		}
	}
	export class GuestBookList
	{
		public static PAGE_SIZE: number = 8;
		public static GetConvertUrl(entry: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.GuestBookList/Convert?entry=${MiaPlaza.RoutedHelper.GetParameter(entry)}&_=`;
		}
		public static GetDeleteUrl(entry: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.GuestBookList/Delete?entry=${MiaPlaza.RoutedHelper.GetParameter(entry)}&_=`;
		}
	}
	export class InventoryList
	{
		public static PAGE_SIZE: number = 12;
		public static LIMIT: number = 1024;
		public static GetConvertUrl(itemInstance: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.InventoryList/Convert?itemInstance=${MiaPlaza.RoutedHelper.GetParameter(itemInstance)}&_=`;
		}
		public static GetDeleteItemUrl(itemInstance: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.InventoryList/DeleteItem?itemInstance=${MiaPlaza.RoutedHelper.GetParameter(itemInstance)}&_=`;
		}
	}
	export class MemberList
	{
		public static PAGE_SIZE: number = 3;
		public static GetConvertUrl(member: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.MemberList/Convert?member=${MiaPlaza.RoutedHelper.GetParameter(member)}&_=`;
		}
	}
	export class MessageList
	{
		public static PAGE_SIZE: number = 5;
		public static GetConvertUrl(mailboxType: MiaPlaza.Content.MessageListType | MiaPlaza.IPlaceholder, message: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.MessageList/Convert?mailboxType=${MiaPlaza.RoutedHelper.GetParameter(mailboxType)}&message=${MiaPlaza.RoutedHelper.GetParameter(message)}&_=`;
		}
		public static GetDeleteMessageUrl(message: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.MessageList/DeleteMessage?message=${MiaPlaza.RoutedHelper.GetParameter(message)}&_=`;
		}
		public static GetMarkMessageAsViewedUrl(message: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.MessageList/MarkMessageAsViewed?message=${MiaPlaza.RoutedHelper.GetParameter(message)}&_=`;
		}
	}
	export class NewspaperArticleList
	{
		public static PAGE_SIZE: number = 3;
		public static GetConvertUrl(entry: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.NewspaperArticleList/Convert?entry=${MiaPlaza.RoutedHelper.GetParameter(entry)}&_=`;
		}
	}
	export class NewspaperPreviewList
	{
		public static PAGE_SIZE: number = 4;
		public static GetConvertUrl(entry: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.NewspaperPreviewList/Convert?entry=${MiaPlaza.RoutedHelper.GetParameter(entry)}&_=`;
		}
	}
	export class TeamList
	{
		public static PAGE_SIZE: number = 5;
		public static GetConvertUrl(entry: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.TeamList/Convert?entry=${MiaPlaza.RoutedHelper.GetParameter(entry)}&_=`;
		}
	}
	export class TransactionList
	{
		public static PAGE_SIZE: number = 3;
		public static GetConvertUrl(transaction: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.TransactionList/Convert?transaction=${MiaPlaza.RoutedHelper.GetParameter(transaction)}&_=`;
		}
	}
	export class WeekLoginReportList
	{
		public static PAGE_SIZE: number = 8;
		public static GetConvertUrl(serializedData: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.WeekLoginReportList/Convert?serializedData=${MiaPlaza.RoutedHelper.GetParameter(serializedData)}&_=`;
		}
	}
	export interface IBozoMemberData
	{
		Name: string;
		Id: string;
		AccountDetailsLink: string;
		ReviewerName: string;
		CreationDate: string;
		MemberState: number;
	}
	export interface IAdminCouponCode
	{
		UUId: string;
		Code: string;
		Expiration: string;
		VisitorCount: number;
		Notes: string;
		IsUsed: boolean;
		CouponCodeDiscounts: MiaPlaza.Control.Vue.Lists.IAdminCouponCodeDiscount[];
		SelectedItemModule: string;
	}
	export interface IAdminCouponCodeDiscount
	{
		UUId: string;
		Discount: number;
		PurchaseCount: number;
		Product: MiaPlaza.Model.Payments.ProductType;
	}
	export interface IAdminSubscriptionEmbedded
	{
		Creation: string;
		Type: string;
		Canceled: boolean;
		Product: string;
		ExternalId: string;
		SyncUrl: string;
		Start: string;
		FutureStartDate: boolean;
		PastDue: boolean;
		PausedUntil: string;
		UnpauseUrl: string;
	}
	export interface ILostPet
	{
		PetPicture: Image;
		OwnerName: string;
		OwnerId: number;
		PetName: string;
		GiveBackLink: string;
	}
	export interface IAdminMember
	{
		Id: string;
		Name: string;
		DetailsUrl: string;
		Type: MiaPlaza.Model.PortalType;
	}
	export interface INewsletterContent
	{
		CreationDate: string;
		OpenLink: string;
		OpenLabel: string;
		Subject: string;
		Status: string;
		Progress: string;
		Action: MiaPlaza.Control.Vue.Lists.NewsletterAction;
		ActionLink: string;
		DuplicateLink: string;
		DeleteLink: string;
		Newsletter: string;
		ShownOnParentPortal: boolean;
	}
	export enum NewsletterAction {
		Approve = "Approve",
		Pause = "Pause",
		Resume = "Resume",
		None = "None"
	}
	export interface IAdminTextResourceData
	{
		Text: string;
		Culture: string;
		PortalInstance: MiaPlaza.Data.PortalInstance;
		TTSMode: MiaPlaza.Model.TTSModeType | null;
		TTSData: string | null;
	}
	export interface IAdminTextResourceExtra
	{
		TTSUrl: string;
	}
	export interface IAdminTextResource
	{
		Key: string;
		Data: MiaPlaza.Control.Vue.Lists.IAdminTextResourceData[];
		Extra: MiaPlaza.Control.Vue.Lists.IAdminTextResourceExtra[];
		DeleteUrl: string;
		EditUrl: string;
		SupportedPortals: MiaPlaza.Model.PortalType[];
		SupportedApplications: MiaPlaza.Data.PortalInstance[];
	}
	export interface IUploadedImage
	{
		Id: number;
		PathOnDisk: string;
		PathOnWebsite: string;
		DeleteLink: string;
		CreationDateAndTime: string;
	}
	export interface IArcadeGame
	{
		Name: string;
		Url: string;
		Thumbnail: Image;
	}
	export interface IArticle
	{
		NewspaperUrl: string;
		NewspaperImage: Image;
		Title: string;
		TitleUrl: string;
		PublishingDate: string;
		Category: string;
	}
	export interface IQuestion
	{
		Text: string[];
		Image: Image;
		Answers: MiaPlaza.Control.Vue.Lists.IAnswer[];
		AnswerStatus: MiaPlaza.Model.PremiumParent.Status;
		IsFreeInputQuestion: boolean;
	}
	export interface IAnswer
	{
		Text: string[];
		WasAnsweredByUser: boolean;
		IsCorrect: boolean;
	}
	export interface IDesignedItem
	{
		Id: number;
		Image: Image;
		SelectedItemModule: string;
	}
	export interface IFriendRequest
	{
		Name: string;
		Avatar: Image;
		MemberUrl: string;
		AcceptUrl: string;
		DeclineUrl: string;
		BlockUrl: string;
	}
	export interface IGift
	{
		Image: Image;
		Link: string;
	}
	export interface IGuestBookEntry
	{
		AuthorName: string;
		CreationDate: string;
		AuthorAvatar: Image;
		AuthorUrl: string;
		Text: string;
		DeleteUrl: string | null;
	}
	export interface InventoryItem
	{
		Id: string;
		Image: Image;
		ImageSrc: string;
		Height: string;
		Width: string;
		Position: boolean;
		DeleteLink: string;
		PetLink: string;
	}
	export interface IMember
	{
		Name: string;
		Birthdate: string;
		AvatarSettings: string;
		Picture: Image;
		Description: string;
		ProfileLink: string;
	}
	export interface IMessage
	{
		Subject: string;
		Text: string;
		MemberName: string;
		MemberAvatar: Image;
		MemberUrl: string;
		Timestamp: string;
		ReplyUrl: string;
		DeleteUrl: string;
		IsNew: boolean;
		IsUnread: boolean;
		DisplayReplyTo: boolean;
		MailboxType: MiaPlaza.Content.MessageListType;
		MessageReadUrl: string;
	}
	export interface INewspaperArticle
	{
		Title: string;
		Body: string;
		CreationDate: string;
		ClickUrl: string;
		LinkText: string;
	}
	export interface ITeam
	{
		TeamName: string;
		TeamUrl: string;
		NumberOfUsers: number;
		IsNew: boolean;
	}
	export interface ITransaction
	{
		Picture: Image;
		Time: string;
		Text: string;
	}
	export interface IWeekLoginReport
	{
		Title: string;
		TotalTime: string;
		DayLoginReports: MiaPlaza.Control.Vue.Lists.IDayLoginReport[];
	}
	export interface IDayLoginReport
	{
		Title: string;
		TotalTime: string;
		LoginActivities: MiaPlaza.Control.Vue.Lists.ILoginActivity[];
	}
	export interface ILoginActivity
	{
		ChildName: string;
		TotalTime: string;
		From: string;
		To: string;
	}
	export class AdminMemberList
	{
		public static GetConvertUrl(member: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.AdminMemberList/Convert?member=${MiaPlaza.RoutedHelper.GetParameter(member)}&_=`;
		}
	}
}
export namespace MiaPlaza.Control.Admin {
	export class TransferTextResources
	{
		public static TRANSFER_CONFIGURATION_CLASS: string = `mia-transferConfiguration`;
		public static TRANSFER_BUTTON_CLASS: string = `mia-transfer`;
		public static DRY_RUN_CHECKBOX_CLASS: string = `mia-dryRun`;
		public static TRANSFER_TRS_TABLE_CLASS: string = `textResourceTransferTable`;
		private static IMPORT_REPLACEMENTS: string = `TextResourceTransferReplacement`;
		public static GetDownloadTextResourcesUrl() : string
		{
			return "/Control.Admin.TransferTextResources/DownloadTextResources?_=";
		}
	}
	export class AccountDetails
	{
		public static GetToggleGovernmentNotAcceptedContentUrl(child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Admin.AccountDetails/ToggleGovernmentNotAcceptedContent?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
	}
}
export namespace MiaPlaza.Navigation.JSON.Converters {
	export interface ILazySizes
	{
		OriginalUrl: string;
		Sizes?: MiaPlaza.Navigation.JSON.Converters.IPictureAtSize[];
		AspectRatio: string;
	}
	export interface IPictureAtSize
	{
		Width: number;
		Url: string;
	}
}
export namespace MiaPlaza.LO.Vimeo {
	export interface IVideoCreationResponse
	{
		UploadLink: string;
		VideoId: string;
	}
}
export namespace MiaPlaza.LO.Payments.Display {
	export interface IPaymentMethodData
	{
		Description: string;
		PaymentMethodType: string;
	}
}
export namespace MiaPlaza.LO.Assessment {
	export interface IProgressStatistics
	{
		Total: number;
		Answered: number;
		AnsweredPercentage: number;
		Correct: number;
		CorrectPercentage: number;
		Wrong: number;
		IsAssessmentPassed: boolean;
	}
}
export namespace MiaPlaza.Controllers {
	export interface IGiveMoneyResult
	{
		Amount: number;
		Successful: boolean;
	}
	export class ChildSettingsController
	{
		public static GetToggleDailyVocabularyPracticeUrl(child: string | MiaPlaza.IPlaceholder, enabled: boolean | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.ChildSettingsController/ToggleDailyVocabularyPractice?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&enabled=${MiaPlaza.RoutedHelper.GetParameter(enabled)}&_=`;
		}
		public static GetToggleDailyVocabularyPracticeAdminUrl(child: string | MiaPlaza.IPlaceholder, enabled: boolean | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.ChildSettingsController/ToggleDailyVocabularyPracticeAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&enabled=${MiaPlaza.RoutedHelper.GetParameter(enabled)}&_=`;
		}
		public static GetToggleCompetitionAccessUrl(child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.ChildSettingsController/ToggleCompetitionAccess?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
		public static GetAcceptRulesUrl(child: string | MiaPlaza.IPlaceholder, type: MiaPlaza.Control.Vue.RulesType | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.ChildSettingsController/AcceptRules?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&type=${MiaPlaza.RoutedHelper.GetParameter(type)}&_=`;
		}
		public static GetToggleTextToSpeechSettingForParentUrl(child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.ChildSettingsController/ToggleTextToSpeechSettingForParent?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
		public static GetToggleTextToSpeechSettingForChildUrl(customGame: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.ChildSettingsController/ToggleTextToSpeechSettingForChild?customGame=${MiaPlaza.RoutedHelper.GetParameter(customGame)}&_=`;
		}
		public static GetToggleFeedbackSoundUrl() : string
		{
			return "/Controllers.ChildSettingsController/ToggleFeedbackSound?_=";
		}
	}
	export class VirtualCurrencyController
	{
		public static GetGiveMoneyToCurrentMemberInGameUrl(amount: number | MiaPlaza.IPlaceholder, game: string | MiaPlaza.IPlaceholder, textResourceKey: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.VirtualCurrencyController/GiveMoneyToCurrentMemberInGame?amount=${MiaPlaza.RoutedHelper.GetParameter(amount)}&game=${MiaPlaza.RoutedHelper.GetParameter(game)}&textResourceKey=${MiaPlaza.RoutedHelper.GetParameter(textResourceKey)}&_=`;
		}
	}
}
export namespace MiaPlaza.Controllers.Video {
	export interface IFilmmakerData
	{
		Name: string;
		Avatar: Image;
		ProfileUrl: string;
	}
	export enum VideoFilterType {
		Highlighted = "Highlighted",
		New = "New",
		Popular = "Popular",
		Trending = "Trending",
		MoreVideos = "MoreVideos"
	}
	export interface IVideoData
	{
		Uploader: MiaPlaza.Controllers.Video.IUploaderData | null;
		AvailableSince: string;
		Thumbnail: Image;
		VideoUrl: string;
		Title: string;
		Duration: string;
		IsNew: boolean;
	}
	export interface IUploaderData
	{
		Author: string;
		AuthorAvatar: Image;
		MemberUrl: string;
	}
	export class FilmmakerController
	{
		public static GetTopFilmmakersUrl() : string
		{
			return "/Controllers.Video.FilmmakerController/GetTopFilmmakers?_=";
		}
		public static GetConvertFilmmakerUrl(child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Video.FilmmakerController/ConvertFilmmaker?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
	}
	export class VideoListController
	{
		public static GetVideosUrl(child: string | MiaPlaza.IPlaceholder, kind: MiaPlaza.Controllers.Video.VideoFilterType | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Video.VideoListController/GetVideos?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&kind=${MiaPlaza.RoutedHelper.GetParameter(kind)}&_=`;
		}
		public static GetSearchResultsUrl(query: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Video.VideoListController/GetSearchResults?query=${MiaPlaza.RoutedHelper.GetParameter(query)}&_=`;
		}
		public static GetUserUploadedVideosUrl(videoUploader: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Video.VideoListController/GetUserUploadedVideos?videoUploader=${MiaPlaza.RoutedHelper.GetParameter(videoUploader)}&_=`;
		}
		public static GetConvertVideoUrl(video: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Video.VideoListController/ConvertVideo?video=${MiaPlaza.RoutedHelper.GetParameter(video)}&_=`;
		}
	}
	export class VideoWatchController
	{
		public static GetDeleteVideoUrl(video: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Video.VideoWatchController/DeleteVideo?video=${MiaPlaza.RoutedHelper.GetParameter(video)}&_=`;
		}
	}
	export class VimeoVideoController
	{
		public static GetCreateVideoUrl(videoName: string | MiaPlaza.IPlaceholder, hintsForModerator: string | MiaPlaza.IPlaceholder, fileSize: number | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Video.VimeoVideoController/CreateVideo?videoName=${MiaPlaza.RoutedHelper.GetParameter(videoName)}&hintsForModerator=${MiaPlaza.RoutedHelper.GetParameter(hintsForModerator)}&fileSize=${MiaPlaza.RoutedHelper.GetParameter(fileSize)}&_=`;
		}
	}
}
export namespace MiaPlaza.Controllers.TeamAchievements {
	export interface ITeamAchievements
	{
		TeamName: string;
		TeamProfileLink: string;
		GoldMedals: number;
		SilverMedals: number;
		BronzeMedals: number;
	}
	export class TeamAchievementsController
	{
		public static GetTopTeamsUrl() : string
		{
			return "/Controllers.TeamAchievements.TeamAchievementsController/GetTopTeams?_=";
		}
		public static GetConvertUrl(team: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.TeamAchievements.TeamAchievementsController/Convert?team=${MiaPlaza.RoutedHelper.GetParameter(team)}&_=`;
		}
	}
}
export namespace MiaPlaza.Controllers.ShoutBox {
	export interface IShoutboxLineData
	{
		ShoutboxLine: string;
		Author: string;
		Text: string;
		ProfileImage: Image;
		ProfileLink: string;
		CreationDate: string;
		CanDelete: boolean;
		CanPublish: boolean;
	}
	export interface ISubmitCommentResult
	{
		Successful: boolean;
		Message: string;
	}
	export class ShoutBoxListController
	{
		public static GetShoutboxLinesUrl(child: string | MiaPlaza.IPlaceholder, shoutBox: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.ShoutBox.ShoutBoxListController/GetShoutboxLines?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&shoutBox=${MiaPlaza.RoutedHelper.GetParameter(shoutBox)}&_=`;
		}
		public static GetConvertUrl(shoutboxLine: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.ShoutBox.ShoutBoxListController/Convert?shoutboxLine=${MiaPlaza.RoutedHelper.GetParameter(shoutboxLine)}&_=`;
		}
		public static GetDeleteCommentUrl(shoutboxLine: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.ShoutBox.ShoutBoxListController/DeleteComment?shoutboxLine=${MiaPlaza.RoutedHelper.GetParameter(shoutboxLine)}&_=`;
		}
		public static GetPublishCommentUrl(shoutboxLine: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.ShoutBox.ShoutBoxListController/PublishComment?shoutboxLine=${MiaPlaza.RoutedHelper.GetParameter(shoutboxLine)}&_=`;
		}
		public static GetSubmitCommentUrl(shoutbox: string | MiaPlaza.IPlaceholder, comment: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.ShoutBox.ShoutBoxListController/SubmitComment?shoutbox=${MiaPlaza.RoutedHelper.GetParameter(shoutbox)}&comment=${MiaPlaza.RoutedHelper.GetParameter(comment)}&_=`;
		}
	}
}
export namespace MiaPlaza.Controllers.Public {
	export interface ISubjectData
	{
		Subject: MiaPlaza.Model.Subject;
		Units: string[];
		Video: MiaPlaza.Controllers.Public.IVideoData;
	}
	export interface IVideoData
	{
		VideoSrc: string;
		ThumbnailSrc: string;
	}
	export class SubjectsController
	{
		public static GetSubjectUrl(subject: MiaPlaza.Model.Subject | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Public.SubjectsController/GetSubject?subject=${MiaPlaza.RoutedHelper.GetParameter(subject)}&_=`;
		}
		public static GetSubjectsUrl() : string
		{
			return "/Controllers.Public.SubjectsController/GetSubjects?_=";
		}
	}
}
export namespace MiaPlaza.Controllers.Shop {
	export interface IMiniWorldDraft
	{
		MiniworldDraft: string;
		Image: string;
		Name: string;
		RedirectLink: string;
	}
	export interface IShapeData
	{
		Shape: Image;
	}
	export interface IDesignTemplateData
	{
		Thumbnail: Image;
		Price: number;
		Url: string;
	}
	export interface IDesignedItemData
	{
		Author: MiaPlaza.Controllers.Shop.IAuthorData;
		Item: string;
		Price: number;
		ProductionCost: number;
		HighestAllowedPrice: number;
		Thumbnail: Image;
		SoldTimes: number;
		Name: string;
		Sale: boolean;
		ItemShopType: MiaPlaza.Control.Games.Shop.PublicShopType;
	}
	export interface IAuthorData
	{
		Id: string;
		Name: string;
		Avatar: Image;
		ProfileUrl: string;
		ShopUrl: string;
		HouseUrl: string;
		CastleType: number;
	}
	export class MiniWorldDraftController
	{
		public static GetDraftsUrl() : string
		{
			return "/Controllers.Shop.MiniWorldDraftController/GetDrafts?_=";
		}
		public static GetConvertDraftUrl(draft: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Shop.MiniWorldDraftController/ConvertDraft?draft=${MiaPlaza.RoutedHelper.GetParameter(draft)}&_=`;
		}
		public static GetDeleteUrl(draft: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Shop.MiniWorldDraftController/Delete?draft=${MiaPlaza.RoutedHelper.GetParameter(draft)}&_=`;
		}
	}
	export class PrivateShopController
	{
		public static GetShopOwnerNameUrl(child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Shop.PrivateShopController/GetShopOwnerName?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
		public static GetSearchItemsUrl(child: string | MiaPlaza.IPlaceholder, searchLine: string | MiaPlaza.IPlaceholder, shape: string | null | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Shop.PrivateShopController/GetSearchItems?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&searchLine=${MiaPlaza.RoutedHelper.GetParameter(searchLine)}&shape=${MiaPlaza.RoutedHelper.GetParameter(shape)}&_=`;
		}
		public static GetShoutboxDataUrl(shopOwner: string | MiaPlaza.IPlaceholder, currentMember: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Shop.PrivateShopController/GetShoutboxData?shopOwner=${MiaPlaza.RoutedHelper.GetParameter(shopOwner)}&currentMember=${MiaPlaza.RoutedHelper.GetParameter(currentMember)}&_=`;
		}
	}
	export class PublicShopController
	{
		public static GetNewItemsUrl(shopType: MiaPlaza.Control.Games.Shop.PublicShopType | MiaPlaza.IPlaceholder, shape: string | null | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Shop.PublicShopController/GetNewItems?shopType=${MiaPlaza.RoutedHelper.GetParameter(shopType)}&shape=${MiaPlaza.RoutedHelper.GetParameter(shape)}&_=`;
		}
		public static GetBestSellersItemsUrl(shopType: MiaPlaza.Control.Games.Shop.PublicShopType | MiaPlaza.IPlaceholder, shape: string | null | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Shop.PublicShopController/GetBestSellersItems?shopType=${MiaPlaza.RoutedHelper.GetParameter(shopType)}&shape=${MiaPlaza.RoutedHelper.GetParameter(shape)}&_=`;
		}
		public static GetSearchItemsUrl(shopType: MiaPlaza.Control.Games.Shop.PublicShopType | MiaPlaza.IPlaceholder, searchLine: string | MiaPlaza.IPlaceholder, shape: string | null | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Shop.PublicShopController/GetSearchItems?shopType=${MiaPlaza.RoutedHelper.GetParameter(shopType)}&searchLine=${MiaPlaza.RoutedHelper.GetParameter(searchLine)}&shape=${MiaPlaza.RoutedHelper.GetParameter(shape)}&_=`;
		}
		public static GetShoutboxDataUrl(child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Shop.PublicShopController/GetShoutboxData?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
	}
	export class ShapeController
	{
		public static GetShapesForPublicShopUrl(shopType: MiaPlaza.Control.Games.Shop.PublicShopType | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Shop.ShapeController/GetShapesForPublicShop?shopType=${MiaPlaza.RoutedHelper.GetParameter(shopType)}&_=`;
		}
		public static GetShapesForTemplateShopUrl(shopType: MiaPlaza.Control.Games.Shop.PublicShopType | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Shop.ShapeController/GetShapesForTemplateShop?shopType=${MiaPlaza.RoutedHelper.GetParameter(shopType)}&_=`;
		}
		public static GetShapesForPrivateShopUrl(owner: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Shop.ShapeController/GetShapesForPrivateShop?owner=${MiaPlaza.RoutedHelper.GetParameter(owner)}&_=`;
		}
		public static GetShoutboxDataUrl(child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Shop.ShapeController/GetShoutboxData?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
		public static GetConvertShapeUrl(shapeItem: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Shop.ShapeController/ConvertShape?shapeItem=${MiaPlaza.RoutedHelper.GetParameter(shapeItem)}&_=`;
		}
		public static GetConvertDesignTemplateUrl(shapeItem: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Shop.ShapeController/ConvertDesignTemplate?shapeItem=${MiaPlaza.RoutedHelper.GetParameter(shapeItem)}&_=`;
		}
	}
	export class ShopController
	{
		public static GetUpdatePriceUrl(item: string | MiaPlaza.IPlaceholder, newPrice: number | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Shop.ShopController/UpdatePrice?item=${MiaPlaza.RoutedHelper.GetParameter(item)}&newPrice=${MiaPlaza.RoutedHelper.GetParameter(newPrice)}&_=`;
		}
		public static GetRemoveFromShopUrl(item: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Shop.ShopController/RemoveFromShop?item=${MiaPlaza.RoutedHelper.GetParameter(item)}&_=`;
		}
		public static GetBuyItemUrl(item: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Shop.ShopController/BuyItem?item=${MiaPlaza.RoutedHelper.GetParameter(item)}&_=`;
		}
		public static GetConvertItemUrl(item: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Shop.ShopController/ConvertItem?item=${MiaPlaza.RoutedHelper.GetParameter(item)}&_=`;
		}
	}
}
export namespace MiaPlaza.Controllers.MiniWorld {
	export interface IHouse
	{
		ChildName: string;
		ChildProfileLink: string;
		MiniWorldHouseLink: string;
		ChildAvatar: Image;
		Castle: Image;
	}
	export class MiniWorldController
	{
		public static GetTopHouseMembersUrl() : string
		{
			return "/Controllers.MiniWorld.MiniWorldController/GetTopHouseMembers?_=";
		}
		public static GetConvertUrl(member: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.MiniWorld.MiniWorldController/Convert?member=${MiaPlaza.RoutedHelper.GetParameter(member)}&_=`;
		}
		public static GetUpgradeHouseUrl() : string
		{
			return "/Controllers.MiniWorld.MiniWorldController/UpgradeHouse?_=";
		}
	}
}
export namespace MiaPlaza.Controllers.Members {
	export interface IMemberProfileData
	{
		Name: string;
		Picture: Image;
		ProfileLink: string;
	}
	export class FriendController
	{
		public static GetAllFriendsUrl(searchQuery?: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Members.FriendController/GetAllFriends?searchQuery=${MiaPlaza.RoutedHelper.GetParameter(searchQuery)}&_=`;
		}
		public static GetAllBlockedOnesFromCurrentUserUrl(searchQuery?: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Members.FriendController/GetAllBlockedOnesFromCurrentUser?searchQuery=${MiaPlaza.RoutedHelper.GetParameter(searchQuery)}&_=`;
		}
		public static GetUnfriendMemberUrl(friendToRemove: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Members.FriendController/UnfriendMember?friendToRemove=${MiaPlaza.RoutedHelper.GetParameter(friendToRemove)}&_=`;
		}
		public static GetUnblockMemberUrl(memberBlockItem: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Members.FriendController/UnblockMember?memberBlockItem=${MiaPlaza.RoutedHelper.GetParameter(memberBlockItem)}&_=`;
		}
		public static GetConvertUrl(member: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Members.FriendController/Convert?member=${MiaPlaza.RoutedHelper.GetParameter(member)}&_=`;
		}
	}
	export class GradeController
	{
		public static GetChangeVocabularyGradeUrl(child: string | MiaPlaza.IPlaceholder, grade: MiaPlaza.Model.Grades.Grade | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Members.GradeController/ChangeVocabularyGrade?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&grade=${MiaPlaza.RoutedHelper.GetParameter(grade)}&_=`;
		}
		public static GetChangeVocabularyGradeAdminUrl(child: string | MiaPlaza.IPlaceholder, grade: MiaPlaza.Model.Grades.Grade | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Members.GradeController/ChangeVocabularyGradeAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&grade=${MiaPlaza.RoutedHelper.GetParameter(grade)}&_=`;
		}
	}
	export abstract class PrivacyController
	{
		public static GetSetFullStoryTrackingConsentUrl(fullStoryTrackingConsent: boolean | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Members.PrivacyController/SetFullStoryTrackingConsent?fullStoryTrackingConsent=${MiaPlaza.RoutedHelper.GetParameter(fullStoryTrackingConsent)}&_=`;
		}
		public static GetToggleFullStoryTrackingConsentUrl() : string
		{
			return "/Controllers.Members.PrivacyController/ToggleFullStoryTrackingConsent?_=";
		}
	}
}
export namespace MiaPlaza.Controllers.Lessons {
	export interface ILessonData
	{
		Name: string;
		Image: Image;
		Link: string;
		HighestMedal: MiaPlaza.Model.Games.MedalType;
		Subject: MiaPlaza.Model.Subject;
		CheckpointAssessmentInLesson: boolean;
		IsExcused: boolean;
	}
	export interface IUnitData
	{
		Title: string;
		CourseTitle: string;
		IsExcused: boolean;
	}
	export class LessonsController
	{
		public static GetLessonsUrl(unit: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Lessons.LessonsController/GetLessons?unit=${MiaPlaza.RoutedHelper.GetParameter(unit)}&_=`;
		}
		public static GetSearchLessonsUrl(searchString: string | MiaPlaza.IPlaceholder, subject?: MiaPlaza.Model.Subject | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Lessons.LessonsController/SearchLessons?searchString=${MiaPlaza.RoutedHelper.GetParameter(searchString)}&subject=${MiaPlaza.RoutedHelper.GetParameter(subject)}&_=`;
		}
		public static GetConvertLessonUrl(game: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Lessons.LessonsController/ConvertLesson?game=${MiaPlaza.RoutedHelper.GetParameter(game)}&_=`;
		}
	}
	export class UnitsController
	{
		public static GetUnitsUrl(subject: MiaPlaza.Model.Subject | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Lessons.UnitsController/GetUnits?subject=${MiaPlaza.RoutedHelper.GetParameter(subject)}&_=`;
		}
		public static GetConvertUnitUrl(unit: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Lessons.UnitsController/ConvertUnit?unit=${MiaPlaza.RoutedHelper.GetParameter(unit)}&_=`;
		}
	}
}
export namespace MiaPlaza.Controllers.Gradebook {
	export interface IGradebookCourse
	{
		Course: string;
		Subject: MiaPlaza.Model.Subject;
		Title: string;
		GradedCheckpoints: number;
		TotalCheckpoints: number;
		CompletedUngradedCheckpoints: number;
		InDevelopment: boolean;
		Grade: MiaPlaza.LO.Curriculum.ICourseGrade;
		StartDate: string;
		EndDate: string;
		Progress: MiaPlaza.LO.Curriculum.ICourseProgress;
		Credits: number;
	}
	export interface IGradebookAssignment
	{
		Title: string;
		IsAssessment: boolean;
		HasPassingGrade: boolean;
		TaskCompleted: boolean;
		Completion: string | null;
		Grade: number | null;
		Attempts: number | null;
		BestAttemptUUId: string | null;
		IsConsideredPassed: boolean | null;
		IsExcused: boolean;
		Course: string;
	}
	export interface IQuizAssessmentAttempt
	{
		Finished: string;
		Score: number;
		Passed: boolean;
	}
	export interface ICheckpointGrade
	{
		Completion: string | null;
		Grade: number | null;
	}
	export abstract class GradebookController
	{
		public static GetCoursesParentUrl(child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Gradebook.GradebookController/GetCoursesParent?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
		public static GetCoursesAdminUrl(child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Gradebook.GradebookController/GetCoursesAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
		public static GetCoursesChildUrl(child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Gradebook.GradebookController/GetCoursesChild?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
		public static GetAssignmentsParentUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Gradebook.GradebookController/GetAssignmentsParent?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&_=`;
		}
		public static GetAssignmentsAdminUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Gradebook.GradebookController/GetAssignmentsAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&_=`;
		}
		public static GetAssignmentsChildUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Gradebook.GradebookController/GetAssignmentsChild?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&_=`;
		}
		public static GetFinishedQuizAssessmentAttemptsParentUrl(child: string | MiaPlaza.IPlaceholder, task: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Gradebook.GradebookController/GetFinishedQuizAssessmentAttemptsParent?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&task=${MiaPlaza.RoutedHelper.GetParameter(task)}&_=`;
		}
		public static GetFinishedQuizAssessmentAttemptsAdminUrl(child: string | MiaPlaza.IPlaceholder, task: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Gradebook.GradebookController/GetFinishedQuizAssessmentAttemptsAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&task=${MiaPlaza.RoutedHelper.GetParameter(task)}&_=`;
		}
		public static GetFinishedQuizAssessmentAttemptsChildUrl(child: string | MiaPlaza.IPlaceholder, task: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Gradebook.GradebookController/GetFinishedQuizAssessmentAttemptsChild?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&task=${MiaPlaza.RoutedHelper.GetParameter(task)}&_=`;
		}
		public static GetTaskAssignmentsUrl(child: string | MiaPlaza.IPlaceholder, task: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Gradebook.GradebookController/GetTaskAssignments?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&task=${MiaPlaza.RoutedHelper.GetParameter(task)}&_=`;
		}
		public static GetSetManualGradeUrl(task: string | MiaPlaza.IPlaceholder, child: string | MiaPlaza.IPlaceholder, grade: number | MiaPlaza.IPlaceholder, completion: string | null | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Gradebook.GradebookController/SetManualGrade?task=${MiaPlaza.RoutedHelper.GetParameter(task)}&child=${MiaPlaza.RoutedHelper.GetParameter(child)}&grade=${MiaPlaza.RoutedHelper.GetParameter(grade)}&completion=${MiaPlaza.RoutedHelper.GetParameter(completion)}&_=`;
		}
		public static GetManualGradeUrl(task: string | MiaPlaza.IPlaceholder, child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Gradebook.GradebookController/GetManualGrade?task=${MiaPlaza.RoutedHelper.GetParameter(task)}&child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
		public static GetRemoveManualGradeUrl(task: string | MiaPlaza.IPlaceholder, child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Gradebook.GradebookController/RemoveManualGrade?task=${MiaPlaza.RoutedHelper.GetParameter(task)}&child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
		public static GetConvertCourseParentUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Gradebook.GradebookController/ConvertCourseParent?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&_=`;
		}
		public static GetConvertCourseAdminUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Gradebook.GradebookController/ConvertCourseAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&_=`;
		}
		public static GetConvertCourseChildUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Gradebook.GradebookController/ConvertCourseChild?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&_=`;
		}
		public static GetConvertAssessmentAttemptAdminUrl(quizAssessmentGoal: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Gradebook.GradebookController/ConvertAssessmentAttemptAdmin?quizAssessmentGoal=${MiaPlaza.RoutedHelper.GetParameter(quizAssessmentGoal)}&_=`;
		}
		public static GetConvertAssessmentAttemptParentUrl(child: string | MiaPlaza.IPlaceholder, quizAssessmentGoal: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Gradebook.GradebookController/ConvertAssessmentAttemptParent?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&quizAssessmentGoal=${MiaPlaza.RoutedHelper.GetParameter(quizAssessmentGoal)}&_=`;
		}
		public static GetConvertAssessmentAttemptChildUrl(child: string | MiaPlaza.IPlaceholder, quizAssessmentGoal: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Gradebook.GradebookController/ConvertAssessmentAttemptChild?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&quizAssessmentGoal=${MiaPlaza.RoutedHelper.GetParameter(quizAssessmentGoal)}&_=`;
		}
		public static GetConvertAssignmentParentUrl(child: string | MiaPlaza.IPlaceholder, task: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Gradebook.GradebookController/ConvertAssignmentParent?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&task=${MiaPlaza.RoutedHelper.GetParameter(task)}&_=`;
		}
		public static GetConvertAssignmentAdminUrl(child: string | MiaPlaza.IPlaceholder, task: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Gradebook.GradebookController/ConvertAssignmentAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&task=${MiaPlaza.RoutedHelper.GetParameter(task)}&_=`;
		}
		public static GetConvertAssignmentChildUrl(child: string | MiaPlaza.IPlaceholder, task: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Gradebook.GradebookController/ConvertAssignmentChild?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&task=${MiaPlaza.RoutedHelper.GetParameter(task)}&_=`;
		}
	}
}
export namespace MiaPlaza.Controllers.Curriculum {
	export interface IAssignedCourseData extends MiaPlaza.Controllers.Curriculum.ICourseData
	{
		LearningPathTask: MiaPlaza.Controllers.Curriculum.ILearningPathTaskData;
		State: MiaPlaza.LO.CourseState;
	}
	export interface ICourseData
	{
		Title: string;
	}
	export interface ILearningPathTaskData
	{
		TaskType: MiaPlaza.Model.Curriculum.Task.TaskType;
		ThumbnailTitle: string;
		Thumbnail: Image;
		Icon: Image;
		Description: string;
		Link: string;
		ScheduledForToday: boolean;
		CheckpointAssessment: boolean;
	}
	export interface IVocabularyTaskData
	{
		LearningPathTask: MiaPlaza.Controllers.Curriculum.ILearningPathTaskData;
		HasCompletedVocabularyTaskForToday: boolean;
		IsLocked: boolean;
	}
	export class LearningPathController
	{
		public static GetCoursesWithTaskAssignmentsUrl(child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.LearningPathController/GetCoursesWithTaskAssignments?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
		public static GetCoursesWithCompletedTaskAssignmentsUrl(child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.LearningPathController/GetCoursesWithCompletedTaskAssignments?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
		public static GetVocabularyTaskUrl(child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.LearningPathController/GetVocabularyTask?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
		public static GetConvertCourseUrl(course: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.LearningPathController/ConvertCourse?course=${MiaPlaza.RoutedHelper.GetParameter(course)}&_=`;
		}
		public static GetConvertAssignedCourseUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.LearningPathController/ConvertAssignedCourse?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&_=`;
		}
		public static GetCompletedTaskAssignmentsUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.LearningPathController/GetCompletedTaskAssignments?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&_=`;
		}
		public static GetConvertTaskAssignmentUrl(taskAssignment: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.LearningPathController/ConvertTaskAssignment?taskAssignment=${MiaPlaza.RoutedHelper.GetParameter(taskAssignment)}&_=`;
		}
	}
}
export namespace MiaPlaza.Controllers.Curriculum.Schedule {
	export interface IDayData
	{
		CustomBreak?: MiaPlaza.Controllers.Curriculum.Schedule.ICustomBreakData;
		PublicHoliday?: MiaPlaza.Controllers.Curriculum.Schedule.IPublicHolidayData;
	}
	export interface ICustomBreakData
	{
		Name: string;
	}
	export interface IPublicHolidayData
	{
		PublicHoliday: MiaPlaza.Model.PublicHoliday;
		Observed: boolean;
	}
	export interface ISearchTaskAssignmentData extends MiaPlaza.LO.Curriculum.Display.ITaskAssignmentData
	{
		ScheduledDate: string;
		Unit: string;
		Subject: MiaPlaza.Model.Subject;
	}
	export interface IUnitData
	{
		Subject: MiaPlaza.Model.Subject;
		Title: string;
		CourseTitle: string;
	}
	export class DayController
	{
		public static GetConvertUrl(date: string | MiaPlaza.IPlaceholder, child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Schedule.DayController/Convert?date=${MiaPlaza.RoutedHelper.GetParameter(date)}&child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
		public static GetConvertAdminUrl(date: string | MiaPlaza.IPlaceholder, child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Schedule.DayController/ConvertAdmin?date=${MiaPlaza.RoutedHelper.GetParameter(date)}&child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
	}
	export class ScheduleController
	{
		public static GetScheduleUrl(child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Schedule.ScheduleController/Schedule?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
		public static GetScheduleAdminUrl(child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Schedule.ScheduleController/ScheduleAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
		public static GetScheduleChildUrl() : string
		{
			return "/Controllers.Curriculum.Schedule.ScheduleController/ScheduleChild?_=";
		}
	}
	export class SearchController
	{
		public static GetTaskAssignmentsOrderedUrl(child: string | MiaPlaza.IPlaceholder, lessonSearch: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Schedule.SearchController/GetTaskAssignmentsOrdered?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&lessonSearch=${MiaPlaza.RoutedHelper.GetParameter(lessonSearch)}&_=`;
		}
		public static GetTaskAssignmentsOrderedAdminUrl(child: string | MiaPlaza.IPlaceholder, lessonSearch: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Schedule.SearchController/GetTaskAssignmentsOrderedAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&lessonSearch=${MiaPlaza.RoutedHelper.GetParameter(lessonSearch)}&_=`;
		}
		public static GetConvertTaskAssignmentUrl(taskAssignment: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Schedule.SearchController/ConvertTaskAssignment?taskAssignment=${MiaPlaza.RoutedHelper.GetParameter(taskAssignment)}&_=`;
		}
	}
	export class TaskAssignmentController
	{
		public static GetTaskAssignmentsScheduledAtUrl(child: string | MiaPlaza.IPlaceholder, unit: string | MiaPlaza.IPlaceholder, scheduledDate: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Schedule.TaskAssignmentController/GetTaskAssignmentsScheduledAt?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&unit=${MiaPlaza.RoutedHelper.GetParameter(unit)}&scheduledDate=${MiaPlaza.RoutedHelper.GetParameter(scheduledDate)}&_=`;
		}
		public static GetTaskAssignmentsScheduledAtAdminUrl(child: string | MiaPlaza.IPlaceholder, unit: string | MiaPlaza.IPlaceholder, scheduledDate: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Schedule.TaskAssignmentController/GetTaskAssignmentsScheduledAtAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&unit=${MiaPlaza.RoutedHelper.GetParameter(unit)}&scheduledDate=${MiaPlaza.RoutedHelper.GetParameter(scheduledDate)}&_=`;
		}
		public static GetConvertTaskAssignmentUrl(taskAssignment: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Schedule.TaskAssignmentController/ConvertTaskAssignment?taskAssignment=${MiaPlaza.RoutedHelper.GetParameter(taskAssignment)}&_=`;
		}
	}
	export class UnitController
	{
		public static GetUnitsScheduledAtUrl(child: string | MiaPlaza.IPlaceholder, subject: MiaPlaza.Model.Subject | MiaPlaza.IPlaceholder, scheduledDate: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Schedule.UnitController/GetUnitsScheduledAt?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&subject=${MiaPlaza.RoutedHelper.GetParameter(subject)}&scheduledDate=${MiaPlaza.RoutedHelper.GetParameter(scheduledDate)}&_=`;
		}
		public static GetUnitsScheduledAtAdminUrl(child: string | MiaPlaza.IPlaceholder, subject: MiaPlaza.Model.Subject | MiaPlaza.IPlaceholder, scheduledDate: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Schedule.UnitController/GetUnitsScheduledAtAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&subject=${MiaPlaza.RoutedHelper.GetParameter(subject)}&scheduledDate=${MiaPlaza.RoutedHelper.GetParameter(scheduledDate)}&_=`;
		}
		public static GetConvertUnitUrl(unit: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Schedule.UnitController/ConvertUnit?unit=${MiaPlaza.RoutedHelper.GetParameter(unit)}&_=`;
		}
	}
}
export namespace MiaPlaza.Controllers.Curriculum.LessonView {
	export interface ILessonFlavorData
	{
		Icon: Image;
		IsAssigned: boolean;
		IsLocked: boolean;
		IsCompleted: boolean;
		AssignedElementIndex: number;
		CheckpointAssessmentInFlavor: boolean;
	}
	export interface IFlavorContent
	{
		Name: string;
		IsAssigned: boolean;
		IsExcused: boolean;
		IsCompleted: boolean;
		IsLocked: boolean;
		Flavor: MiaPlaza.Model.Curriculum.Task.Flavor;
		Component: string;
	}
	export interface IAssessmentData extends MiaPlaza.Controllers.Curriculum.LessonView.IFlavorContent
	{
		Score: number | null;
		ThumbnailBackground: Image;
		Lesson: string;
	}
	export interface IPracticeData extends MiaPlaza.Controllers.Curriculum.LessonView.IFlavorContent
	{
		Medal: Image;
		Thumbnail: Image;
		Lesson: string;
	}
	export interface IVideoData extends MiaPlaza.Controllers.Curriculum.LessonView.IFlavorContent
	{
		Thumbnail: Image;
		ContentLink: string;
	}
	export interface ISupplementalMaterialData extends MiaPlaza.Controllers.Curriculum.LessonView.IFlavorContent
	{
		SupplementalMaterialType: MiaPlaza.Model.Games.SupplementalMaterialType;
		ThumbnailBackground: Image;
		Icon: Image;
		CheckpointAssessment: boolean;
		ContentLink: string;
	}
	export interface ILightboxContent<T>
	{
		Content: T;
		Component: string;
		Mode: MiaPlaza.LO.Curriculum.Display.LightboxMode;
	}
	export interface ILessonContent<T>
	{
		Content: T;
		Component: string;
	}
	export interface IPdfData
	{
		Url: string;
		Title: string;
	}
	export interface IPracticeAndAssessment
	{
		Version: string;
		AudioSettings: MiaPlaza.LO.Games.IAudioSettings;
		FreeInput: boolean;
	}
	export class FlavorController
	{
		public static GetFlavorsUrl(child: string | MiaPlaza.IPlaceholder, game: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.LessonView.FlavorController/GetFlavors?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&game=${MiaPlaza.RoutedHelper.GetParameter(game)}&_=`;
		}
		public static GetConvertFlavorUrl(flavor: MiaPlaza.Model.Curriculum.Task.Flavor | MiaPlaza.IPlaceholder, child: string | MiaPlaza.IPlaceholder, game: string | MiaPlaza.IPlaceholder, isAssignmentMode: boolean | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.LessonView.FlavorController/ConvertFlavor?flavor=${MiaPlaza.RoutedHelper.GetParameter(flavor)}&child=${MiaPlaza.RoutedHelper.GetParameter(child)}&game=${MiaPlaza.RoutedHelper.GetParameter(game)}&isAssignmentMode=${MiaPlaza.RoutedHelper.GetParameter(isAssignmentMode)}&_=`;
		}
	}
	export class TaskController
	{
		public static GetAvailableTasksUrl(child: string | MiaPlaza.IPlaceholder, game: string | MiaPlaza.IPlaceholder, flavor: MiaPlaza.Model.Curriculum.Task.Flavor | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.LessonView.TaskController/GetAvailableTasks?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&game=${MiaPlaza.RoutedHelper.GetParameter(game)}&flavor=${MiaPlaza.RoutedHelper.GetParameter(flavor)}&_=`;
		}
		public static GetConvertTaskUrl(child: string | MiaPlaza.IPlaceholder, task: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.LessonView.TaskController/ConvertTask?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&task=${MiaPlaza.RoutedHelper.GetParameter(task)}&_=`;
		}
		public static GetReportSupplementalTaskViewedUrl(child: string | MiaPlaza.IPlaceholder, supplementalTask: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.LessonView.TaskController/ReportSupplementalTaskViewed?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&supplementalTask=${MiaPlaza.RoutedHelper.GetParameter(supplementalTask)}&_=`;
		}
	}
	export class TaskPreviewController
	{
		public static GetGameContentPracticeUrl(game: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.LessonView.TaskPreviewController/GetGameContentPractice?game=${MiaPlaza.RoutedHelper.GetParameter(game)}&_=`;
		}
		public static GetGameContentAssessmentUrl(game: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.LessonView.TaskPreviewController/GetGameContentAssessment?game=${MiaPlaza.RoutedHelper.GetParameter(game)}&_=`;
		}
		public static GetEmbeddedGameContentUrl(url: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.LessonView.TaskPreviewController/GetEmbeddedGameContent?url=${MiaPlaza.RoutedHelper.GetParameter(url)}&_=`;
		}
		public static GetEmbeddedPdfContentUrl(url: string | MiaPlaza.IPlaceholder, title: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.LessonView.TaskPreviewController/GetEmbeddedPdfContent?url=${MiaPlaza.RoutedHelper.GetParameter(url)}&title=${MiaPlaza.RoutedHelper.GetParameter(title)}&_=`;
		}
		public static GetVideoContentUrl(child: string | MiaPlaza.IPlaceholder, video: string | MiaPlaza.IPlaceholder, lesson: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.LessonView.TaskPreviewController/GetVideoContent?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&video=${MiaPlaza.RoutedHelper.GetParameter(video)}&lesson=${MiaPlaza.RoutedHelper.GetParameter(lesson)}&_=`;
		}
	}
}
export namespace MiaPlaza.Controllers.Curriculum.Configuration {
	export interface ICourseData
	{
		Title: string;
		Description: string;
		RecommendedGradeMin: number;
		RecommendedGradeMax: number;
		RecommendedLength: number;
		RecommendedSchoolWeekLength: number;
		Enrolled: boolean;
		EnrollmentStatus: MiaPlaza.LO.Curriculum.Configuration.CourseEnrollmentStatus;
		InDevelopment: boolean;
		Credits: number;
	}
	export interface ICourseEnrollmentData
	{
		Title: string;
		Course: string;
		UUId: string;
		Status: MiaPlaza.LO.Curriculum.Configuration.CourseEnrollmentStatus;
		Start: string;
		End: string;
		SchoolWeek: string[];
		InDevelopment: boolean;
	}
	export interface ICustomBreakData
	{
		Name: string;
		Start: string;
		End: string;
	}
	export interface ILessonData
	{
		Name: string;
		Progress: string | null;
		IsTextToSpeechEnabled: boolean;
		CheckpointAssessmentInLesson: boolean;
		IsExcused: boolean;
	}
	export interface IPublicHolidayData
	{
		Date: string;
		Selected: boolean;
		Observed: boolean;
	}
	export interface ITaskData
	{
		Task: string;
		Name: string;
		TaskType: MiaPlaza.Model.Curriculum.Task.TaskType;
		PreviewData: MiaPlaza.LO.Curriculum.Display.IPreviewData;
		Progress: string;
		CheckpointAssessment: boolean;
		IsExcused: boolean;
	}
	export interface IUnitData
	{
		Title: string;
		PDFs: { [key:string]: MiaPlaza.Controllers.Curriculum.Configuration.IPDFLink[] };
	}
	export interface IPDFLink
	{
		Title: string;
		PreviewData: MiaPlaza.LO.Curriculum.Display.IPreviewData;
	}
	export interface IVocabularyQuestion
	{
		Word: string;
		Meaning: string;
	}
	export class CourseController
	{
		public static GetCoursesUrl(child: string | MiaPlaza.IPlaceholder, subject: MiaPlaza.Model.Subject | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CourseController/GetCourses?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&subject=${MiaPlaza.RoutedHelper.GetParameter(subject)}&_=`;
		}
		public static GetCoursesAdminUrl(child: string | MiaPlaza.IPlaceholder, subject: MiaPlaza.Model.Subject | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CourseController/GetCoursesAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&subject=${MiaPlaza.RoutedHelper.GetParameter(subject)}&_=`;
		}
		public static GetConvertCourseUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CourseController/ConvertCourse?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&_=`;
		}
		public static GetConvertCourseAdminUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CourseController/ConvertCourseAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&_=`;
		}
		public static GetEnrollUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CourseController/Enroll?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&_=`;
		}
		public static GetEnrollAdminUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CourseController/EnrollAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&_=`;
		}
		public static GetReenrollUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CourseController/Reenroll?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&_=`;
		}
		public static GetReenrollAdminUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CourseController/ReenrollAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&_=`;
		}
		public static GetStateUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CourseController/GetState?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&_=`;
		}
		public static GetStateAdminUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CourseController/GetStateAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&_=`;
		}
		public static GetUnenrollUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CourseController/Unenroll?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&_=`;
		}
		public static GetUnenrollAdminUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CourseController/UnenrollAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&_=`;
		}
	}
	export class CourseEnrollmentController
	{
		public static GetEnrolledCoursesUrl(child: string | MiaPlaza.IPlaceholder, subject: MiaPlaza.Model.Subject | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CourseEnrollmentController/GetEnrolledCourses?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&subject=${MiaPlaza.RoutedHelper.GetParameter(subject)}&_=`;
		}
		public static GetEnrolledCoursesAdminUrl(child: string | MiaPlaza.IPlaceholder, subject: MiaPlaza.Model.Subject | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CourseEnrollmentController/GetEnrolledCoursesAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&subject=${MiaPlaza.RoutedHelper.GetParameter(subject)}&_=`;
		}
		public static GetConvertEnrolledCourseUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CourseEnrollmentController/ConvertEnrolledCourse?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&_=`;
		}
		public static GetConvertEnrolledCourseAdminUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CourseEnrollmentController/ConvertEnrolledCourseAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&_=`;
		}
		public static GetUpdateCourseEnrollmentUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder, start: string | MiaPlaza.IPlaceholder, end: string | MiaPlaza.IPlaceholder, schoolWeeks: string[] | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CourseEnrollmentController/UpdateCourseEnrollment?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&start=${MiaPlaza.RoutedHelper.GetParameter(start)}&end=${MiaPlaza.RoutedHelper.GetParameter(end)}&schoolWeeks=${MiaPlaza.RoutedHelper.GetParameter(schoolWeeks)}&_=`;
		}
		public static GetUpdateCourseEnrollmentAdminUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder, start: string | MiaPlaza.IPlaceholder, end: string | MiaPlaza.IPlaceholder, schoolWeeks: string[] | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CourseEnrollmentController/UpdateCourseEnrollmentAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&start=${MiaPlaza.RoutedHelper.GetParameter(start)}&end=${MiaPlaza.RoutedHelper.GetParameter(end)}&schoolWeeks=${MiaPlaza.RoutedHelper.GetParameter(schoolWeeks)}&_=`;
		}
		public static GetSetCourseEnrollmentIsRequiredUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder, isRequired: boolean | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CourseEnrollmentController/SetCourseEnrollmentIsRequired?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&isRequired=${MiaPlaza.RoutedHelper.GetParameter(isRequired)}&_=`;
		}
		public static GetSetCourseEnrollmentIsRequiredAdminUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder, isRequired: boolean | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CourseEnrollmentController/SetCourseEnrollmentIsRequiredAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&isRequired=${MiaPlaza.RoutedHelper.GetParameter(isRequired)}&_=`;
		}
		public static GetSetCourseEnrollmentPassingScorePercentageUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder, passingScorePercentage: number | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CourseEnrollmentController/SetCourseEnrollmentPassingScorePercentage?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&passingScorePercentage=${MiaPlaza.RoutedHelper.GetParameter(passingScorePercentage)}&_=`;
		}
		public static GetSetCourseEnrollmentPassingScorePercentageAdminUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder, passingScorePercentage: number | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CourseEnrollmentController/SetCourseEnrollmentPassingScorePercentageAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&passingScorePercentage=${MiaPlaza.RoutedHelper.GetParameter(passingScorePercentage)}&_=`;
		}
		public static GetEditCourseEnrollmentDataUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CourseEnrollmentController/GetEditCourseEnrollmentData?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&_=`;
		}
		public static GetEditCourseEnrollmentDataAdminUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CourseEnrollmentController/GetEditCourseEnrollmentDataAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&_=`;
		}
		public static GetSetCourseEnrollmentIsFreeInputPreferableUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder, isFreeInputPreferable: boolean | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CourseEnrollmentController/SetCourseEnrollmentIsFreeInputPreferable?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&isFreeInputPreferable=${MiaPlaza.RoutedHelper.GetParameter(isFreeInputPreferable)}&_=`;
		}
		public static GetSetCourseEnrollmentIsFreeInputPreferableAdminUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder, isFreeInputPreferable: boolean | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CourseEnrollmentController/SetCourseEnrollmentIsFreeInputPreferableAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&isFreeInputPreferable=${MiaPlaza.RoutedHelper.GetParameter(isFreeInputPreferable)}&_=`;
		}
		public static GetCourseEnrollmentStatusUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CourseEnrollmentController/GetCourseEnrollmentStatus?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&_=`;
		}
		public static GetCourseEnrollmentStatusAdminUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CourseEnrollmentController/GetCourseEnrollmentStatusAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&_=`;
		}
	}
	export class CustomBreaksController
	{
		public static GetFutureCustomBreaksUrl(child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CustomBreaksController/GetFutureCustomBreaks?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
		public static GetFutureCustomBreaksAdminUrl(child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CustomBreaksController/GetFutureCustomBreaksAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
		public static GetConvertCustomBreakUrl(customBreak: string | MiaPlaza.IPlaceholder, child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CustomBreaksController/ConvertCustomBreak?customBreak=${MiaPlaza.RoutedHelper.GetParameter(customBreak)}&child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
		public static GetConvertCustomBreakAdminUrl(customBreak: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CustomBreaksController/ConvertCustomBreakAdmin?customBreak=${MiaPlaza.RoutedHelper.GetParameter(customBreak)}&_=`;
		}
		public static GetCreateCustomBreakUrl(child: string | MiaPlaza.IPlaceholder, name: string | MiaPlaza.IPlaceholder, start: string | MiaPlaza.IPlaceholder, end: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CustomBreaksController/CreateCustomBreak?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&name=${MiaPlaza.RoutedHelper.GetParameter(name)}&start=${MiaPlaza.RoutedHelper.GetParameter(start)}&end=${MiaPlaza.RoutedHelper.GetParameter(end)}&_=`;
		}
		public static GetCreateCustomBreakAdminUrl(child: string | MiaPlaza.IPlaceholder, name: string | MiaPlaza.IPlaceholder, start: string | MiaPlaza.IPlaceholder, end: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CustomBreaksController/CreateCustomBreakAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&name=${MiaPlaza.RoutedHelper.GetParameter(name)}&start=${MiaPlaza.RoutedHelper.GetParameter(start)}&end=${MiaPlaza.RoutedHelper.GetParameter(end)}&_=`;
		}
		public static GetUpdateCustomBreakUrl(child: string | MiaPlaza.IPlaceholder, customBreak: string | MiaPlaza.IPlaceholder, name: string | MiaPlaza.IPlaceholder, start: string | MiaPlaza.IPlaceholder, end: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CustomBreaksController/UpdateCustomBreak?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&customBreak=${MiaPlaza.RoutedHelper.GetParameter(customBreak)}&name=${MiaPlaza.RoutedHelper.GetParameter(name)}&start=${MiaPlaza.RoutedHelper.GetParameter(start)}&end=${MiaPlaza.RoutedHelper.GetParameter(end)}&_=`;
		}
		public static GetUpdateCustomBreakAdminUrl(customBreak: string | MiaPlaza.IPlaceholder, name: string | MiaPlaza.IPlaceholder, start: string | MiaPlaza.IPlaceholder, end: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CustomBreaksController/UpdateCustomBreakAdmin?customBreak=${MiaPlaza.RoutedHelper.GetParameter(customBreak)}&name=${MiaPlaza.RoutedHelper.GetParameter(name)}&start=${MiaPlaza.RoutedHelper.GetParameter(start)}&end=${MiaPlaza.RoutedHelper.GetParameter(end)}&_=`;
		}
		public static GetDeleteCustomBreakUrl(customBreak: string | MiaPlaza.IPlaceholder, child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CustomBreaksController/DeleteCustomBreak?customBreak=${MiaPlaza.RoutedHelper.GetParameter(customBreak)}&child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
		public static GetDeleteCustomBreakAdminUrl(customBreak: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.CustomBreaksController/DeleteCustomBreakAdmin?customBreak=${MiaPlaza.RoutedHelper.GetParameter(customBreak)}&_=`;
		}
	}
	export class LessonController
	{
		public static GetAssignUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder, lesson: string | MiaPlaza.IPlaceholder, reassign: boolean | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.LessonController/Assign?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&lesson=${MiaPlaza.RoutedHelper.GetParameter(lesson)}&reassign=${MiaPlaza.RoutedHelper.GetParameter(reassign)}&_=`;
		}
		public static GetAssignAdminUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder, lesson: string | MiaPlaza.IPlaceholder, reassign: boolean | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.LessonController/AssignAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&lesson=${MiaPlaza.RoutedHelper.GetParameter(lesson)}&reassign=${MiaPlaza.RoutedHelper.GetParameter(reassign)}&_=`;
		}
		public static GetUnassignUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder, lesson: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.LessonController/Unassign?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&lesson=${MiaPlaza.RoutedHelper.GetParameter(lesson)}&_=`;
		}
		public static GetUnassignAdminUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder, lesson: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.LessonController/UnassignAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&lesson=${MiaPlaza.RoutedHelper.GetParameter(lesson)}&_=`;
		}
		public static GetExcuseAdminUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder, lesson: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.LessonController/ExcuseAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&lesson=${MiaPlaza.RoutedHelper.GetParameter(lesson)}&_=`;
		}
		public static GetUnexcuseAdminUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder, lesson: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.LessonController/UnexcuseAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&lesson=${MiaPlaza.RoutedHelper.GetParameter(lesson)}&_=`;
		}
		public static GetIsAssignedUrl(child: string | MiaPlaza.IPlaceholder, lesson: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.LessonController/IsAssigned?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&lesson=${MiaPlaza.RoutedHelper.GetParameter(lesson)}&_=`;
		}
		public static GetIsAssignedAdminUrl(child: string | MiaPlaza.IPlaceholder, lesson: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.LessonController/IsAssignedAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&lesson=${MiaPlaza.RoutedHelper.GetParameter(lesson)}&_=`;
		}
		public static GetStateUrl(child: string | MiaPlaza.IPlaceholder, lesson: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.LessonController/GetState?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&lesson=${MiaPlaza.RoutedHelper.GetParameter(lesson)}&_=`;
		}
		public static GetStateAdminUrl(child: string | MiaPlaza.IPlaceholder, lesson: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.LessonController/GetStateAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&lesson=${MiaPlaza.RoutedHelper.GetParameter(lesson)}&_=`;
		}
		public static GetLessonsUrl(child: string | MiaPlaza.IPlaceholder, unit: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.LessonController/GetLessons?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&unit=${MiaPlaza.RoutedHelper.GetParameter(unit)}&_=`;
		}
		public static GetLessonsAdminUrl(child: string | MiaPlaza.IPlaceholder, unit: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.LessonController/GetLessonsAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&unit=${MiaPlaza.RoutedHelper.GetParameter(unit)}&_=`;
		}
		public static GetConvertLessonUrl(child: string | MiaPlaza.IPlaceholder, lesson: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.LessonController/ConvertLesson?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&lesson=${MiaPlaza.RoutedHelper.GetParameter(lesson)}&_=`;
		}
		public static GetConvertLessonAdminUrl(child: string | MiaPlaza.IPlaceholder, lesson: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.LessonController/ConvertLessonAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&lesson=${MiaPlaza.RoutedHelper.GetParameter(lesson)}&_=`;
		}
	}
	export class PublicHolidaysController
	{
		public static GetNamesUrl(child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.PublicHolidaysController/GetNames?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
		public static GetNamesAdminUrl(child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.PublicHolidaysController/GetNamesAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
		public static GetConvertUrl(publicHoliday: MiaPlaza.Model.PublicHoliday | MiaPlaza.IPlaceholder, child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.PublicHolidaysController/Convert?publicHoliday=${MiaPlaza.RoutedHelper.GetParameter(publicHoliday)}&child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
		public static GetConvertAdminUrl(publicHoliday: MiaPlaza.Model.PublicHoliday | MiaPlaza.IPlaceholder, child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.PublicHolidaysController/ConvertAdmin?publicHoliday=${MiaPlaza.RoutedHelper.GetParameter(publicHoliday)}&child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
		public static GetIncludePublicHolidayUrl(child: string | MiaPlaza.IPlaceholder, publicHoliday: MiaPlaza.Model.PublicHoliday | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.PublicHolidaysController/IncludePublicHoliday?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&publicHoliday=${MiaPlaza.RoutedHelper.GetParameter(publicHoliday)}&_=`;
		}
		public static GetIncludePublicHolidayAdminUrl(child: string | MiaPlaza.IPlaceholder, publicHoliday: MiaPlaza.Model.PublicHoliday | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.PublicHolidaysController/IncludePublicHolidayAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&publicHoliday=${MiaPlaza.RoutedHelper.GetParameter(publicHoliday)}&_=`;
		}
		public static GetExcludePublicHolidayUrl(child: string | MiaPlaza.IPlaceholder, publicHoliday: MiaPlaza.Model.PublicHoliday | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.PublicHolidaysController/ExcludePublicHoliday?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&publicHoliday=${MiaPlaza.RoutedHelper.GetParameter(publicHoliday)}&_=`;
		}
		public static GetExcludePublicHolidayAdminUrl(child: string | MiaPlaza.IPlaceholder, publicHoliday: MiaPlaza.Model.PublicHoliday | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.PublicHolidaysController/ExcludePublicHolidayAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&publicHoliday=${MiaPlaza.RoutedHelper.GetParameter(publicHoliday)}&_=`;
		}
	}
	export class TaskController
	{
		public static GetAssignUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder, task: string | MiaPlaza.IPlaceholder, reassign: boolean | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.TaskController/Assign?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&task=${MiaPlaza.RoutedHelper.GetParameter(task)}&reassign=${MiaPlaza.RoutedHelper.GetParameter(reassign)}&_=`;
		}
		public static GetAssignAdminUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder, task: string | MiaPlaza.IPlaceholder, reassign: boolean | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.TaskController/AssignAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&task=${MiaPlaza.RoutedHelper.GetParameter(task)}&reassign=${MiaPlaza.RoutedHelper.GetParameter(reassign)}&_=`;
		}
		public static GetExcuseAdminUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder, task: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.TaskController/ExcuseAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&task=${MiaPlaza.RoutedHelper.GetParameter(task)}&_=`;
		}
		public static GetExcuseCheckpointAdminUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder, task: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.TaskController/ExcuseCheckpointAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&task=${MiaPlaza.RoutedHelper.GetParameter(task)}&_=`;
		}
		public static GetUnassignUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder, task: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.TaskController/Unassign?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&task=${MiaPlaza.RoutedHelper.GetParameter(task)}&_=`;
		}
		public static GetUnassignAdminUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder, task: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.TaskController/UnassignAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&task=${MiaPlaza.RoutedHelper.GetParameter(task)}&_=`;
		}
		public static GetUnexcuseAdminUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder, task: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.TaskController/UnexcuseAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&task=${MiaPlaza.RoutedHelper.GetParameter(task)}&_=`;
		}
		public static GetIsAssignedUrl(child: string | MiaPlaza.IPlaceholder, task: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.TaskController/IsAssigned?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&task=${MiaPlaza.RoutedHelper.GetParameter(task)}&_=`;
		}
		public static GetIsAssignedAdminUrl(child: string | MiaPlaza.IPlaceholder, task: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.TaskController/IsAssignedAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&task=${MiaPlaza.RoutedHelper.GetParameter(task)}&_=`;
		}
		public static GetStateUrl(child: string | MiaPlaza.IPlaceholder, task: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.TaskController/GetState?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&task=${MiaPlaza.RoutedHelper.GetParameter(task)}&_=`;
		}
		public static GetStateAdminUrl(child: string | MiaPlaza.IPlaceholder, task: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.TaskController/GetStateAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&task=${MiaPlaza.RoutedHelper.GetParameter(task)}&_=`;
		}
		public static GetTaskFlavorsUrl(lesson: string | MiaPlaza.IPlaceholder, child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.TaskController/GetTaskFlavors?lesson=${MiaPlaza.RoutedHelper.GetParameter(lesson)}&child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
		public static GetTaskFlavorsAdminUrl(lesson: string | MiaPlaza.IPlaceholder, child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.TaskController/GetTaskFlavorsAdmin?lesson=${MiaPlaza.RoutedHelper.GetParameter(lesson)}&child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
		public static GetAssignedTasksCountUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.TaskController/GetAssignedTasksCount?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&_=`;
		}
		public static GetAssignedTasksCountAdminUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.TaskController/GetAssignedTasksCountAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&_=`;
		}
		public static GetConvertTaskFlavorUrl(child: string | MiaPlaza.IPlaceholder, lesson: string | MiaPlaza.IPlaceholder, flavor: MiaPlaza.Model.Curriculum.Task.Flavor | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.TaskController/ConvertTaskFlavor?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&lesson=${MiaPlaza.RoutedHelper.GetParameter(lesson)}&flavor=${MiaPlaza.RoutedHelper.GetParameter(flavor)}&_=`;
		}
		public static GetConvertTaskFlavorAdminUrl(child: string | MiaPlaza.IPlaceholder, lesson: string | MiaPlaza.IPlaceholder, flavor: MiaPlaza.Model.Curriculum.Task.Flavor | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.TaskController/ConvertTaskFlavorAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&lesson=${MiaPlaza.RoutedHelper.GetParameter(lesson)}&flavor=${MiaPlaza.RoutedHelper.GetParameter(flavor)}&_=`;
		}
		public static GetQuizAssessmentDetailsRedirectUrl(child: string | MiaPlaza.IPlaceholder, lesson: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.TaskController/QuizAssessmentDetailsRedirect?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&lesson=${MiaPlaza.RoutedHelper.GetParameter(lesson)}&_=`;
		}
		public static GetQuizAssessmentDetailsRedirectAdminUrl(child: string | MiaPlaza.IPlaceholder, lesson: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.TaskController/QuizAssessmentDetailsRedirectAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&lesson=${MiaPlaza.RoutedHelper.GetParameter(lesson)}&_=`;
		}
		public static GetNumberOfCompletedTasksUrl(child: string | MiaPlaza.IPlaceholder, date: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.TaskController/GetNumberOfCompletedTasks?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&date=${MiaPlaza.RoutedHelper.GetParameter(date)}&_=`;
		}
		public static GetNumberOfCompletedTasksAdminUrl(child: string | MiaPlaza.IPlaceholder, date: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.TaskController/GetNumberOfCompletedTasksAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&date=${MiaPlaza.RoutedHelper.GetParameter(date)}&_=`;
		}
	}
	export class UnitController
	{
		public static GetAssignUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder, unit: string | MiaPlaza.IPlaceholder, reassign: boolean | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.UnitController/Assign?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&unit=${MiaPlaza.RoutedHelper.GetParameter(unit)}&reassign=${MiaPlaza.RoutedHelper.GetParameter(reassign)}&_=`;
		}
		public static GetAssignAdminUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder, unit: string | MiaPlaza.IPlaceholder, reassign: boolean | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.UnitController/AssignAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&unit=${MiaPlaza.RoutedHelper.GetParameter(unit)}&reassign=${MiaPlaza.RoutedHelper.GetParameter(reassign)}&_=`;
		}
		public static GetExcuseAdminUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder, unit: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.UnitController/ExcuseAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&unit=${MiaPlaza.RoutedHelper.GetParameter(unit)}&_=`;
		}
		public static GetUnassignUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder, unit: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.UnitController/Unassign?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&unit=${MiaPlaza.RoutedHelper.GetParameter(unit)}&_=`;
		}
		public static GetUnassignAdminUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder, unit: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.UnitController/UnassignAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&unit=${MiaPlaza.RoutedHelper.GetParameter(unit)}&_=`;
		}
		public static GetUnexcuseAdminUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder, unit: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.UnitController/UnexcuseAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&unit=${MiaPlaza.RoutedHelper.GetParameter(unit)}&_=`;
		}
		public static GetStateUrl(child: string | MiaPlaza.IPlaceholder, unit: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.UnitController/GetState?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&unit=${MiaPlaza.RoutedHelper.GetParameter(unit)}&_=`;
		}
		public static GetStateAdminUrl(child: string | MiaPlaza.IPlaceholder, unit: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.UnitController/GetStateAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&unit=${MiaPlaza.RoutedHelper.GetParameter(unit)}&_=`;
		}
		public static GetUnitsUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.UnitController/GetUnits?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&_=`;
		}
		public static GetUnitsAdminUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.UnitController/GetUnitsAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&_=`;
		}
		public static GetConvertUnitUrl(child: string | MiaPlaza.IPlaceholder, unit: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.UnitController/ConvertUnit?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&unit=${MiaPlaza.RoutedHelper.GetParameter(unit)}&_=`;
		}
		public static GetConvertUnitAdminUrl(child: string | MiaPlaza.IPlaceholder, unit: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.UnitController/ConvertUnitAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&unit=${MiaPlaza.RoutedHelper.GetParameter(unit)}&_=`;
		}
	}
	export class VocabularyController
	{
		public static GetVocabularyQuestionsUrl(child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.VocabularyController/GetVocabularyQuestions?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
		public static GetVocabularyQuestionsAdminUrl(child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.VocabularyController/GetVocabularyQuestionsAdmin?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
	}
	export class TaskPreviewController
	{
		public static GetLightboxVideoPreviewUrl(video: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Curriculum.Configuration.TaskPreviewController/GetLightboxVideoPreview?video=${MiaPlaza.RoutedHelper.GetParameter(video)}&_=`;
		}
	}
}
export namespace MiaPlaza.Control.Vue.Video {
	export interface IUploader
	{
		Name: string;
		ProfileLink: string;
	}
}
export namespace MiaPlaza.Control.Vue.Payments {
	export interface IAdminChildInfo
	{
		Id: string;
		Name: string;
		ExpirationDate: string;
	}
	export interface IAdminPaymentInfo
	{
		Id: string;
		PaidThroughDate: string;
		MaxCancellationDate: string;
		CanBeVoided: boolean;
	}
	export class AdminGiveFreeMembership
	{
		public static GetGiveFreeMembershipUrl(product: string | MiaPlaza.IPlaceholder, child: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Payments.AdminGiveFreeMembership/GiveFreeMembership?product=${MiaPlaza.RoutedHelper.GetParameter(product)}&child=${MiaPlaza.RoutedHelper.GetParameter(child)}&_=`;
		}
		public static GetGiveFreeMembershipFixedEndDateUrl(child: string | MiaPlaza.IPlaceholder, day: number | MiaPlaza.IPlaceholder, month: number | MiaPlaza.IPlaceholder, year: number | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Payments.AdminGiveFreeMembership/GiveFreeMembershipFixedEndDate?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&day=${MiaPlaza.RoutedHelper.GetParameter(day)}&month=${MiaPlaza.RoutedHelper.GetParameter(month)}&year=${MiaPlaza.RoutedHelper.GetParameter(year)}&_=`;
		}
	}
}
export namespace MiaPlaza.Control.Vue.Lists.Searchable {
	export interface IAdminEditableCouponCode
	{
		Code: string;
		Expiration: string;
		Notes: string;
		CouponCodeDiscounts: MiaPlaza.Control.Vue.Lists.Searchable.IAdminEditableCouponCodeDiscount[];
	}
	export interface IAdminEditableCouponCodeDiscount
	{
		Discount: number;
		Product: MiaPlaza.Model.Payments.ProductType;
	}
	export interface ISubjectFilteringOptions
	{
		Subject: MiaPlaza.Model.Subject;
		CourseOptions: MiaPlaza.Control.Vue.Lists.Searchable.ICourseFilteringOptions[];
	}
	export interface ICourseFilteringOptions
	{
		CourseName: string;
		Course: string;
	}
	export class AdminCouponCodeEditor
	{
		public static GetCouponCodesUrl(expired: boolean | MiaPlaza.IPlaceholder, code: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.Searchable.AdminCouponCodeEditor/GetCouponCodes?expired=${MiaPlaza.RoutedHelper.GetParameter(expired)}&code=${MiaPlaza.RoutedHelper.GetParameter(code)}&_=`;
		}
		public static GetCreateUrl(editableCouponCode: MiaPlaza.Control.Vue.Lists.Searchable.IAdminEditableCouponCode | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.Searchable.AdminCouponCodeEditor/Create?editableCouponCode=${MiaPlaza.RoutedHelper.GetParameter(editableCouponCode)}&_=`;
		}
		public static GetEditUrl(editableCouponCode: MiaPlaza.Control.Vue.Lists.Searchable.IAdminEditableCouponCode | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.Searchable.AdminCouponCodeEditor/Edit?editableCouponCode=${MiaPlaza.RoutedHelper.GetParameter(editableCouponCode)}&_=`;
		}
	}
	export class AdminLostPetSearch
	{
		public static GetLostPetsUrl(memberId: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.Searchable.AdminLostPetSearch/GetLostPets?memberId=${MiaPlaza.RoutedHelper.GetParameter(memberId)}&_=`;
		}
	}
	export class AdminTextResourceSearch
	{
		public static GetTextResourcesUrl(key: string | MiaPlaza.IPlaceholder, text: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.Searchable.AdminTextResourceSearch/GetTextResources?key=${MiaPlaza.RoutedHelper.GetParameter(key)}&text=${MiaPlaza.RoutedHelper.GetParameter(text)}&_=`;
		}
		public static GetCreateEmptyUrl(key: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.Searchable.AdminTextResourceSearch/CreateEmpty?key=${MiaPlaza.RoutedHelper.GetParameter(key)}&_=`;
		}
	}
	export class MemberSearch
	{
		public static GetMembersUrl(searchNameAndCode: string | MiaPlaza.IPlaceholder, searchInterest: string | MiaPlaza.IPlaceholder, group: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.Searchable.MemberSearch/GetMembers?searchNameAndCode=${MiaPlaza.RoutedHelper.GetParameter(searchNameAndCode)}&searchInterest=${MiaPlaza.RoutedHelper.GetParameter(searchInterest)}&group=${MiaPlaza.RoutedHelper.GetParameter(group)}&_=`;
		}
	}
	export class NewspaperPreviewSearch
	{
		public static GetNewspaperArticlesUrl(newspaperEdition: string | MiaPlaza.IPlaceholder, newspaperCategory: string | MiaPlaza.IPlaceholder, search: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.Searchable.NewspaperPreviewSearch/GetNewspaperArticles?newspaperEdition=${MiaPlaza.RoutedHelper.GetParameter(newspaperEdition)}&newspaperCategory=${MiaPlaza.RoutedHelper.GetParameter(newspaperCategory)}&search=${MiaPlaza.RoutedHelper.GetParameter(search)}&_=`;
		}
	}
	export class TeamSearch
	{
		public static GetTeamSuggestionsUrl(member: string | MiaPlaza.IPlaceholder, search: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.Searchable.TeamSearch/GetTeamSuggestions?member=${MiaPlaza.RoutedHelper.GetParameter(member)}&search=${MiaPlaza.RoutedHelper.GetParameter(search)}&_=`;
		}
	}
	export class WeekLoginReportSearch
	{
		public static GetAttendanceUrl(child: string | MiaPlaza.IPlaceholder, start: string | MiaPlaza.IPlaceholder, end: string | MiaPlaza.IPlaceholder, allChildren: boolean | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Lists.Searchable.WeekLoginReportSearch/GetAttendance?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&start=${MiaPlaza.RoutedHelper.GetParameter(start)}&end=${MiaPlaza.RoutedHelper.GetParameter(end)}&allChildren=${MiaPlaza.RoutedHelper.GetParameter(allChildren)}&_=`;
		}
	}
}
export namespace MiaPlaza.Control.Vue.BestStreak {
	export interface IRewardData
	{
		Duration: number;
		Image: Image | null;
		Scoops: number;
	}
}
export namespace MiaPlaza.Control.Vue.Admin {
	export interface IContentSynchronizationState
	{
		ToBeDeletedUnits: [string,boolean][];
		ToBeDeletedCourses: [string,boolean][];
		ToBeDeletedLessons: string[];
		NotInstalledLessons: string[];
		ToBeDeletedGames: string[];
		NotInstalledGames: string[];
		Version: string;
		Error: string;
	}
	export interface IConfigurationSection
	{
		Name: string;
		Version: string;
		Data: { [key:string]: string };
	}
	export class RegulatorSections
	{
		public static GetReloadUrl(section: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Vue.Admin.RegulatorSections/Reload?section=${MiaPlaza.RoutedHelper.GetParameter(section)}&_=`;
		}
	}
}
export namespace MiaPlaza.Control.Newspaper {
	export interface IExampleArticle
	{
		CategoryId: number;
		Category: string;
		Title: string;
		Body: string;
	}
	export class NewspaperEditor
	{
		public static GetExampleArticlesUrl() : string
		{
			return "/Control.Newspaper.NewspaperEditor/GetExampleArticles?_=";
		}
		public static GetItemsForSaleUrl() : string
		{
			return "/Control.Newspaper.NewspaperEditor/GetItemsForSale?_=";
		}
	}
	export class NewspaperOptions
	{
		public static GetOnWriteBtnClickUrl() : string
		{
			return "/Control.Newspaper.NewspaperOptions/OnWriteBtnClick?_=";
		}
	}
}
export namespace MiaPlaza.Content {
	export enum MessageListType {
		ReceivedYouOnly = "ReceivedYouOnly",
		ReceivedMoreThanYou = "ReceivedMoreThanYou",
		Sent = "Sent",
		Trash = "Trash"
	}
	export class ArcadeGames
	{
		public static GetArcadeGamesUrl() : string
		{
			return "/Content.ArcadeGames/GetArcadeGames?_=";
		}
	}
	export class Community
	{
		public static GetFriendsUrl() : string
		{
			return "/Content.Community/GetFriends?_=";
		}
	}
	export class Message
	{
		public static GetMessagesUrl(mailboxType: MiaPlaza.Content.MessageListType | MiaPlaza.IPlaceholder, isAscendingOrder: boolean | MiaPlaza.IPlaceholder) : string
		{
			return `/Content.Message/GetMessages?mailboxType=${MiaPlaza.RoutedHelper.GetParameter(mailboxType)}&isAscendingOrder=${MiaPlaza.RoutedHelper.GetParameter(isAscendingOrder)}&_=`;
		}
		public static GetToggleSortOrderUrl(mailboxType: MiaPlaza.Content.MessageListType | MiaPlaza.IPlaceholder, isAscendingOrder: boolean | MiaPlaza.IPlaceholder) : string
		{
			return `/Content.Message/ToggleSortOrder?mailboxType=${MiaPlaza.RoutedHelper.GetParameter(mailboxType)}&isAscendingOrder=${MiaPlaza.RoutedHelper.GetParameter(isAscendingOrder)}&_=`;
		}
		public static GetDeleteAllMessagesUrl(mailboxType: MiaPlaza.Content.MessageListType | MiaPlaza.IPlaceholder, isAscendingOrder: boolean | MiaPlaza.IPlaceholder) : string
		{
			return `/Content.Message/DeleteAllMessages?mailboxType=${MiaPlaza.RoutedHelper.GetParameter(mailboxType)}&isAscendingOrder=${MiaPlaza.RoutedHelper.GetParameter(isAscendingOrder)}&_=`;
		}
		public static GetMarkAllMessagesAsReadUrl(mailboxType: MiaPlaza.Content.MessageListType | MiaPlaza.IPlaceholder, isAscendingOrder: boolean | MiaPlaza.IPlaceholder) : string
		{
			return `/Content.Message/MarkAllMessagesAsRead?mailboxType=${MiaPlaza.RoutedHelper.GetParameter(mailboxType)}&isAscendingOrder=${MiaPlaza.RoutedHelper.GetParameter(isAscendingOrder)}&_=`;
		}
	}
	export class MessageSend
	{
		public static GetPreviousConversationsUrl(member: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Content.MessageSend/GetPreviousConversations?member=${MiaPlaza.RoutedHelper.GetParameter(member)}&_=`;
		}
	}
	export class NewspaperAdCreator
	{
		public static GetCreateOrUpdateAdUrl(ad: string | MiaPlaza.IPlaceholder, inDraftMode: boolean | MiaPlaza.IPlaceholder) : string
		{
			return `/Content.NewspaperAdCreator/CreateOrUpdateAd?ad=${MiaPlaza.RoutedHelper.GetParameter(ad)}&inDraftMode=${MiaPlaza.RoutedHelper.GetParameter(inDraftMode)}&_=`;
		}
		public static GetVisibleItemsUrl() : string
		{
			return "/Content.NewspaperAdCreator/GetVisibleItems?_=";
		}
	}
	export class NewspaperCreator
	{
		public static GetDraftsUrl() : string
		{
			return "/Content.NewspaperCreator/GetDrafts?_=";
		}
	}
	export class PostingOverview
	{
		public static GetTeamMembersUrl(team: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Content.PostingOverview/GetTeamMembers?team=${MiaPlaza.RoutedHelper.GetParameter(team)}&_=`;
		}
	}
	export class ScoopsOverview
	{
		public static GetUserTransactionsUrl(member: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Content.ScoopsOverview/GetUserTransactions?member=${MiaPlaza.RoutedHelper.GetParameter(member)}&_=`;
		}
	}
}
export namespace MiaPlaza.Model {
	export enum Lifetime {
		VOLATILE = "VOLATILE",
		UNTIL_SEEN = "UNTIL_SEEN",
		UNTIL_DISMISSED = "UNTIL_DISMISSED"
	}
	export enum PortalType {
		Children = "Children",
		Parents = "Parents"
	}
	export enum TTSModeType {
		Language = "Language",
		Markup = "Markup",
		SoundFile = "SoundFile"
	}
	export enum FeatureAccessLevel {
		NoCommunityFeatures = "NoCommunityFeatures",
		InteractionAllowed = "InteractionAllowed",
		FullSocial = "FullSocial",
		Parent = "Parent"
	}
	export enum SpecialUserType {
		HAPPY_ME = "HAPPY_ME",
		AKI = "AKI",
		ADMIN = "ADMIN",
		DEFAULT_MEMBER = "DEFAULT_MEMBER",
		MODERATOR_CHILD = "MODERATOR_CHILD",
		NEWSPAPER_CORRESPONDENT = "NEWSPAPER_CORRESPONDENT"
	}
	export enum Subject {
		LanguageArts = "LanguageArts",
		Math = "Math",
		Science = "Science",
		GeographyAndHistory = "GeographyAndHistory",
		MusicAndArts = "MusicAndArts",
		ForeignLanguages = "ForeignLanguages",
		LifeSkills = "LifeSkills",
		BiblicalStudies = "BiblicalStudies"
	}
	export enum PublicHoliday {
		NewYearsDay = "NewYearsDay",
		MartinLutherKingJrDay = "MartinLutherKingJrDay",
		PresidentsDay = "PresidentsDay",
		MemorialDay = "MemorialDay",
		Juneteenth = "Juneteenth",
		IndependenceDay = "IndependenceDay",
		LaborDay = "LaborDay",
		VeteransDay = "VeteransDay",
		ThanksgivingDay = "ThanksgivingDay",
		ChristmasDay = "ChristmasDay"
	}
	export enum CheaterStatus {
		None = "None",
		Cheater = "Cheater",
		Watchlist = "Watchlist"
	}
	export enum CompetitionType {
		MostVirtualCurrencyEarned = "MostVirtualCurrencyEarned",
		VirtualCurrencyRaffle = "VirtualCurrencyRaffle"
	}
}
export namespace MiaPlaza.Data {
	export enum PortalInstance {
		Girls = "Girls",
		Boys = "Boys"
	}
}
export namespace MiaPlaza.Model.Curriculum.Task {
	export enum Flavor {
		Teaching = "Teaching",
		Practice = "Practice",
		Assessment = "Assessment",
		Activity = "Activity"
	}
	export enum TaskType {
		Medal = "Medal",
		Video = "Video",
		QuizAssessment = "QuizAssessment",
		Supplemental = "Supplemental"
	}
}
export namespace MiaPlaza.Model.Games {
	export enum SupplementalMaterialType {
		Pdf = "Pdf",
		EmbeddedURL = "EmbeddedURL"
	}
	export enum MedalType {
		None = "None",
		Bronze = "Bronze",
		Silver = "Silver",
		Gold = "Gold",
		Diamond = "Diamond"
	}
}
export namespace MiaPlaza.Model.PremiumParent {
	export interface IPredefinedDateRange
	{
		Start: string;
		End: string;
		Type: MiaPlaza.Model.PremiumParent.PredefinedDateRangeType;
		PredefinedDateRanges: MiaPlaza.Model.PremiumParent.IPredefinedDateRange[];
	}
	export enum PredefinedDateRangeType {
		Today = 0,
		Yesterday = 1,
		SevenDays = 2,
		LastWeek = 3,
		LastMonth = 4,
		LastSchoolYear = 5,
		ThisSchoolYear = 6,
		Lifetime = 7
	}
	export enum Status {
		NoAnswer = "NoAnswer",
		WrongUnknown = "WrongUnknown",
		CorrectAnswer = "CorrectAnswer",
		WrongFirstAnswer = "WrongFirstAnswer",
		WrongSecondAnswer = "WrongSecondAnswer",
		WrongThirdAnswer = "WrongThirdAnswer"
	}
}
export namespace MiaPlaza.Model.Payments {
	export enum ProductType {
		FixedEndDate = "FixedEndDate",
		OneMonth = "OneMonth",
		TrialMonth = "TrialMonth",
		TwoMonths = "TwoMonths",
		SixMonths = "SixMonths",
		TwelveMonths = "TwelveMonths",
		TenYearsFree = "TenYearsFree",
		Lifelong = "Lifelong",
		FamilyLifetime = "FamilyLifetime",
		SubscriptionPayment = "SubscriptionPayment",
		FixedEndDateBundle = "FixedEndDateBundle"
	}
}
export namespace MiaPlaza.Model.Grades {
	export enum Grade {
		K = 0,
		First = 1,
		Second = 2,
		Third = 3,
		Fourth = 4,
		Fifth = 5,
		Sixth = 6,
		Seventh = 7,
		Eighth = 8,
		Ninth = 9,
		Tenth = 10,
		Eleventh = 11,
		Twelfth = 12
	}
}
export namespace MiaPlaza.Control.Games.Shop {
	export enum PublicShopType {
		Clothing = "Clothing",
		Furniture = "Furniture",
		Art = "Art"
	}
}
export namespace MiaPlaza.Model.VirtualCurrency.Transaction {
	export enum PresetTransactionType {
		StartingMoney = "StartingMoney",
		ActiveInvitedFriendReward = "ActiveInvitedFriendReward",
		AdminBonus = "AdminBonus",
		CompetitionReward = "CompetitionReward",
		NewspaperArticlePublishedReward = "NewspaperArticlePublishedReward",
		EducationVideoApprovedReward = "EducationVideoApprovedReward",
		EarlyInvitationBonus = "EarlyInvitationBonus",
		MiniWorldBoughtShopItem = "MiniWorldBoughtShopItem",
		MiniWorldSoldShopItem = "MiniWorldSoldShopItem",
		MiniWorldBoughtShape = "MiniWorldBoughtShape",
		MiniWorldUpgradedHouse = "MiniWorldUpgradedHouse",
		MiniWorldBoughtRoom = "MiniWorldBoughtRoom",
		MiniWorldBoughtDraft = "MiniWorldBoughtDraft",
		GNOMTreasureHuntWin = "GNOMTreasureHuntWin",
		GNOMBoughtItems = "GNOMBoughtItems",
		GNOMBoughtEggs = "GNOMBoughtEggs",
		GNOMBoughtActions = "GNOMBoughtActions",
		GNOMCircusWin = "GNOMCircusWin",
		GNOMBoughtPlayAction = "GNOMBoughtPlayAction"
	}
}
export namespace MiaPlaza.Model.Newsletter {
	export enum Status {
		Design = "Design",
		Sending = "Sending",
		Finished = "Finished",
		Paused = "Paused"
	}
}
export namespace MiaPlaza.ConfigModel.LandingPage {
	export interface IMenuOptions
	{
		Title: string;
		Url: string;
		Submenus: MiaPlaza.ConfigModel.LandingPage.IMenuOptions[];
	}
}
export namespace MiaPlaza.ConfigModel.FAQ {
	export interface IAnsweredQuestion
	{
		Question: string;
		Answer: string;
	}
	export interface IFAQCategory
	{
		Category: string;
		AnsweredQuestions: MiaPlaza.ConfigModel.FAQ.IAnsweredQuestion[];
	}
}
export namespace MiaPlaza.ConfigModel.Video {
	export interface IVideoSetting
	{
		Title: string;
		Url: string;
	}
}
export namespace MiaPlaza.Collections.Multitude {
	export enum OffsetPrecision {
		Exact = "Exact",
		AlmostExact = "AlmostExact",
		Unfiltered = "Unfiltered"
	}
	export enum LimitPrecision {
		Exact = "Exact",
		AlmostExact = "AlmostExact",
		Unfiltered = "Unfiltered"
	}
}
export namespace MiaPlaza.Model.Curriculum {
	export abstract class CourseEnrollment
	{
		public static PASSING_SCORE_MIN: number = 1;
		public static PASSING_SCORE_MAX: number = 100;
	}
	export abstract class CurriculumChildBreak
	{
		public static MAX_BREAK_NAME_LENGTH: number = 50;
	}
}
export namespace MiaPlaza.Parents.Control {
	export class ChangePassword
	{
		public static GetUpdateParentPasswordUrl() : string
		{
			return "/Parents.Control.ChangePassword/UpdateParentPassword?_=";
		}
	}
	export class LoginFormParent
	{
		public static GetSaveDataInSessionsForForgotPasswordPageUrl(email: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Parents.Control.LoginFormParent/SaveDataInSessionsForForgotPasswordPage?email=${MiaPlaza.RoutedHelper.GetParameter(email)}&_=`;
		}
	}
}
export namespace MiaPlaza.Parents.Control.Vue.Payments {
	export class PauseSubscription
	{
		public static GetPauseUrl(subscription: string | MiaPlaza.IPlaceholder, numberOfMonths: number | MiaPlaza.IPlaceholder) : string
		{
			return `/Parents.Control.Vue.Payments.PauseSubscription/Pause?subscription=${MiaPlaza.RoutedHelper.GetParameter(subscription)}&numberOfMonths=${MiaPlaza.RoutedHelper.GetParameter(numberOfMonths)}&_=`;
		}
	}
}
export namespace MiaPlaza.Parents.Control.Premium {
	export class AssessmentQuestions
	{
		public static GetPrintWorkSheetUrl(goal: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Parents.Control.Premium.AssessmentQuestions/PrintWorkSheet?goal=${MiaPlaza.RoutedHelper.GetParameter(goal)}&_=`;
		}
		public static GetPrintWorksheetKeyUrl(goal: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Parents.Control.Premium.AssessmentQuestions/PrintWorksheetKey?goal=${MiaPlaza.RoutedHelper.GetParameter(goal)}&_=`;
		}
		public static GetAssessmentQuestionsUrl(goal: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Parents.Control.Premium.AssessmentQuestions/GetAssessmentQuestions?goal=${MiaPlaza.RoutedHelper.GetParameter(goal)}&_=`;
		}
	}
}
export namespace MiaPlaza.LO.PremiumParent {
	export class RememberTimeSpan
	{
		public static GetSetUrl(predefinedDateRangeType: MiaPlaza.Model.PremiumParent.PredefinedDateRangeType | MiaPlaza.IPlaceholder) : string
		{
			return `/LO.PremiumParent.RememberTimeSpan/Set?predefinedDateRangeType=${MiaPlaza.RoutedHelper.GetParameter(predefinedDateRangeType)}&_=`;
		}
	}
}
export namespace MiaPlaza.Controllers.Reporting {
	export abstract class CourseReportController
	{
		public static GetFilteredCoursesUrl(child: string | MiaPlaza.IPlaceholder, start: string | MiaPlaza.IPlaceholder, end: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Reporting.CourseReportController/GetFilteredCourses?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&start=${MiaPlaza.RoutedHelper.GetParameter(start)}&end=${MiaPlaza.RoutedHelper.GetParameter(end)}&_=`;
		}
		public static GetConvertUrl(child: string | MiaPlaza.IPlaceholder, course: string | MiaPlaza.IPlaceholder, start: string | MiaPlaza.IPlaceholder, end: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Reporting.CourseReportController/Convert?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&course=${MiaPlaza.RoutedHelper.GetParameter(course)}&start=${MiaPlaza.RoutedHelper.GetParameter(start)}&end=${MiaPlaza.RoutedHelper.GetParameter(end)}&_=`;
		}
	}
	export abstract class ReportPrintingController
	{
		public static GetPrintActivityReportPDFUrl(child: string | MiaPlaza.IPlaceholder, start: string | MiaPlaza.IPlaceholder, end: string | MiaPlaza.IPlaceholder, fullName: string | MiaPlaza.IPlaceholder, includeStudyTime: boolean | MiaPlaza.IPlaceholder, reportItems: MiaPlaza.LO.Curriculum.Display.ICourseReport[] | MiaPlaza.IPlaceholder, coursesToReport: string[] | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Reporting.ReportPrintingController/PrintActivityReportPDF?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&start=${MiaPlaza.RoutedHelper.GetParameter(start)}&end=${MiaPlaza.RoutedHelper.GetParameter(end)}&fullName=${MiaPlaza.RoutedHelper.GetParameter(fullName)}&includeStudyTime=${MiaPlaza.RoutedHelper.GetParameter(includeStudyTime)}&reportItems=${MiaPlaza.RoutedHelper.GetParameter(reportItems)}&coursesToReport=${MiaPlaza.RoutedHelper.GetParameter(coursesToReport)}&_=`;
		}
		public static GetPrintActivityReportCSVUrl(child: string | MiaPlaza.IPlaceholder, start: string | MiaPlaza.IPlaceholder, end: string | MiaPlaza.IPlaceholder, reportItems: MiaPlaza.LO.Curriculum.Display.ICourseReport[] | MiaPlaza.IPlaceholder, coursesToReport: string[] | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Reporting.ReportPrintingController/PrintActivityReportCSV?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&start=${MiaPlaza.RoutedHelper.GetParameter(start)}&end=${MiaPlaza.RoutedHelper.GetParameter(end)}&reportItems=${MiaPlaza.RoutedHelper.GetParameter(reportItems)}&coursesToReport=${MiaPlaza.RoutedHelper.GetParameter(coursesToReport)}&_=`;
		}
		public static GetPrintAttendanceReportUrl(child: string | MiaPlaza.IPlaceholder, start: string | MiaPlaza.IPlaceholder, end: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Reporting.ReportPrintingController/PrintAttendanceReport?child=${MiaPlaza.RoutedHelper.GetParameter(child)}&start=${MiaPlaza.RoutedHelper.GetParameter(start)}&end=${MiaPlaza.RoutedHelper.GetParameter(end)}&_=`;
		}
	}
}
export namespace MiaPlaza.Controllers.RankedList.ScoopCompetitionSidebar {
	export class ScoopCompetitionSidebarController
	{
		public static GetCompetitionLeadersUrl() : string
		{
			return "/Controllers.RankedList.ScoopCompetitionSidebar.ScoopCompetitionSidebarController/GetCompetitionLeaders?_=";
		}
		public static GetConvertCompetitionLeadersUrl(moneyMonthly: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.RankedList.ScoopCompetitionSidebar.ScoopCompetitionSidebarController/ConvertCompetitionLeaders?moneyMonthly=${MiaPlaza.RoutedHelper.GetParameter(moneyMonthly)}&_=`;
		}
	}
}
export namespace MiaPlaza.Controllers.RankedList.BestStreakSidebar {
	export class BestStreakSidebarController
	{
		public static GetTopStreaksUrl() : string
		{
			return "/Controllers.RankedList.BestStreakSidebar.BestStreakSidebarController/GetTopStreaks?_=";
		}
		public static GetConvertUrl(streak: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.RankedList.BestStreakSidebar.BestStreakSidebarController/Convert?streak=${MiaPlaza.RoutedHelper.GetParameter(streak)}&_=`;
		}
	}
}
export namespace MiaPlaza.Controllers.Newsletter {
	export class NewsletterController
	{
		public static GetDismissNewsletterUrl(newsletterContent: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Newsletter.NewsletterController/DismissNewsletter?newsletterContent=${MiaPlaza.RoutedHelper.GetParameter(newsletterContent)}&_=`;
		}
		public static GetRenderUrl(newsletterContent: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Newsletter.NewsletterController/Render?newsletterContent=${MiaPlaza.RoutedHelper.GetParameter(newsletterContent)}&_=`;
		}
	}
}
export namespace MiaPlaza.Controllers.Identity {
	export abstract class AccountController
	{
		public static GetConnectFacebookUrl(returnUrl: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Identity.AccountController/ConnectFacebook?returnUrl=${MiaPlaza.RoutedHelper.GetParameter(returnUrl)}&_=`;
		}
		public static GetExternalConnectCallbackUrl(returnUrl: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Identity.AccountController/ExternalConnectCallback?returnUrl=${MiaPlaza.RoutedHelper.GetParameter(returnUrl)}&_=`;
		}
	}
	export abstract class SignInController
	{
		public static GetOneTimeAuthTokenSignInUrl(token: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Identity.SignInController/OneTimeAuthTokenSignIn?token=${MiaPlaza.RoutedHelper.GetParameter(token)}&_=`;
		}
		public static GetSignInWithFacebookUrl(returnUrl: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Identity.SignInController/SignInWithFacebook?returnUrl=${MiaPlaza.RoutedHelper.GetParameter(returnUrl)}&_=`;
		}
		public static GetExternalSignInCallbackUrl(returnUrl: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Identity.SignInController/ExternalSignInCallback?returnUrl=${MiaPlaza.RoutedHelper.GetParameter(returnUrl)}&_=`;
		}
		public static GetExternalAdminSignInCallbackUrl(returnUrl: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Identity.SignInController/ExternalAdminSignInCallback?returnUrl=${MiaPlaza.RoutedHelper.GetParameter(returnUrl)}&_=`;
		}
	}
}
export namespace MiaPlaza.Controllers.Games.Quizzes {
	export class AssessmentController
	{
		public static GetAssessmentContentUrl(customGame: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Games.Quizzes.AssessmentController/GetAssessmentContent?customGame=${MiaPlaza.RoutedHelper.GetParameter(customGame)}&_=`;
		}
		public static GetProcessMultipleChoiceAnswersUrl(customGame: string | MiaPlaza.IPlaceholder, answers: MiaPlaza.LO.PracticeAndAssessment.Dtos.Answers.IMultipleChoiceAnswer[] | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Games.Quizzes.AssessmentController/ProcessMultipleChoiceAnswers?customGame=${MiaPlaza.RoutedHelper.GetParameter(customGame)}&answers=${MiaPlaza.RoutedHelper.GetParameter(answers)}&_=`;
		}
		public static GetProcessFreeInputAnswersUrl(customGame: string | MiaPlaza.IPlaceholder, answers: MiaPlaza.LO.PracticeAndAssessment.Dtos.Answers.IFreeInputAnswer[] | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Games.Quizzes.AssessmentController/ProcessFreeInputAnswers?customGame=${MiaPlaza.RoutedHelper.GetParameter(customGame)}&answers=${MiaPlaza.RoutedHelper.GetParameter(answers)}&_=`;
		}
		public static GetProcessAnswerUrl(game: string | MiaPlaza.IPlaceholder, answeredCorrectly: boolean | MiaPlaza.IPlaceholder, answerId: number | MiaPlaza.IPlaceholder, questionId: number | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Games.Quizzes.AssessmentController/ProcessAnswer?game=${MiaPlaza.RoutedHelper.GetParameter(game)}&answeredCorrectly=${MiaPlaza.RoutedHelper.GetParameter(answeredCorrectly)}&answerId=${MiaPlaza.RoutedHelper.GetParameter(answerId)}&questionId=${MiaPlaza.RoutedHelper.GetParameter(questionId)}&_=`;
		}
		public static GetRestartUrl(game: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Games.Quizzes.AssessmentController/Restart?game=${MiaPlaza.RoutedHelper.GetParameter(game)}&_=`;
		}
		public static GetQuestionDataUrl(game: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Games.Quizzes.AssessmentController/GetQuestionData?game=${MiaPlaza.RoutedHelper.GetParameter(game)}&_=`;
		}
	}
	export class PracticeController
	{
		public static GetPracticeContentUrl(customGame: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Games.Quizzes.PracticeController/GetPracticeContent?customGame=${MiaPlaza.RoutedHelper.GetParameter(customGame)}&_=`;
		}
		public static GetSetLowerLevelUrl(customGame: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Games.Quizzes.PracticeController/SetLowerLevel?customGame=${MiaPlaza.RoutedHelper.GetParameter(customGame)}&_=`;
		}
		public static GetProcessMultipleChoiceAnswersUrl(customGame: string | MiaPlaza.IPlaceholder, answers: MiaPlaza.LO.PracticeAndAssessment.Dtos.Answers.IMultipleChoiceAnswer[] | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Games.Quizzes.PracticeController/ProcessMultipleChoiceAnswers?customGame=${MiaPlaza.RoutedHelper.GetParameter(customGame)}&answers=${MiaPlaza.RoutedHelper.GetParameter(answers)}&_=`;
		}
		public static GetProcessFreeInputAnswersUrl(customGame: string | MiaPlaza.IPlaceholder, answers: MiaPlaza.LO.PracticeAndAssessment.Dtos.Answers.IFreeInputAnswer[] | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Games.Quizzes.PracticeController/ProcessFreeInputAnswers?customGame=${MiaPlaza.RoutedHelper.GetParameter(customGame)}&answers=${MiaPlaza.RoutedHelper.GetParameter(answers)}&_=`;
		}
		public static GetProcessAnswerUrl(customGame: string | MiaPlaza.IPlaceholder, success: boolean | MiaPlaza.IPlaceholder, questionId: number | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Games.Quizzes.PracticeController/ProcessAnswer?customGame=${MiaPlaza.RoutedHelper.GetParameter(customGame)}&success=${MiaPlaza.RoutedHelper.GetParameter(success)}&questionId=${MiaPlaza.RoutedHelper.GetParameter(questionId)}&_=`;
		}
		public static GetJumpLevelUrl(customGame: string | MiaPlaza.IPlaceholder, levelCount: number | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Games.Quizzes.PracticeController/JumpLevel?customGame=${MiaPlaza.RoutedHelper.GetParameter(customGame)}&levelCount=${MiaPlaza.RoutedHelper.GetParameter(levelCount)}&_=`;
		}
		public static GetQuestionDataUrl(customGame: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Games.Quizzes.PracticeController/GetQuestionData?customGame=${MiaPlaza.RoutedHelper.GetParameter(customGame)}&_=`;
		}
	}
}
export namespace MiaPlaza.Controllers.Admin.Newsletter {
	export class NewsletterController
	{
		public static GetRenderPreviewUrl(subject: string | MiaPlaza.IPlaceholder, salutation: string | MiaPlaza.IPlaceholder, message: string | MiaPlaza.IPlaceholder, unsubscribeLink: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.Newsletter.NewsletterController/RenderPreview?subject=${MiaPlaza.RoutedHelper.GetParameter(subject)}&salutation=${MiaPlaza.RoutedHelper.GetParameter(salutation)}&message=${MiaPlaza.RoutedHelper.GetParameter(message)}&unsubscribeLink=${MiaPlaza.RoutedHelper.GetParameter(unsubscribeLink)}&_=`;
		}
		public static GetShowOnParentPortalUrl(newsletterContent: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Controllers.Admin.Newsletter.NewsletterController/ShowOnParentPortal?newsletterContent=${MiaPlaza.RoutedHelper.GetParameter(newsletterContent)}&_=`;
		}
	}
}
export namespace MiaPlaza.Control {
	export class DeleteMessageDesign
	{
		public static GetRenderPreviewUrl(parent: string | MiaPlaza.IPlaceholder, subject: string | MiaPlaza.IPlaceholder, message: string | MiaPlaza.IPlaceholder, itemId: number | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.DeleteMessageDesign/RenderPreview?parent=${MiaPlaza.RoutedHelper.GetParameter(parent)}&subject=${MiaPlaza.RoutedHelper.GetParameter(subject)}&message=${MiaPlaza.RoutedHelper.GetParameter(message)}&itemId=${MiaPlaza.RoutedHelper.GetParameter(itemId)}&_=`;
		}
	}
	export class FriendRequestPending
	{
		public static GetPendingFriendsUrl() : string
		{
			return "/Control.FriendRequestPending/GetPendingFriends?_=";
		}
	}
	export class GiveGiftBox
	{
		public static GetPossibleGiftsUrl() : string
		{
			return "/Control.GiveGiftBox/GetPossibleGifts?_=";
		}
	}
	export class GuestBook
	{
		public static GetGuestBookEntriesUrl(owner: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.GuestBook/GetGuestBookEntries?owner=${MiaPlaza.RoutedHelper.GetParameter(owner)}&_=`;
		}
	}
	export class LoginFormChildren
	{
		public static GetStoreChildDataInSessionForForgotPasswordPageUrl(firstName: string | MiaPlaza.IPlaceholder, lastName: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.LoginFormChildren/StoreChildDataInSessionForForgotPasswordPage?firstName=${MiaPlaza.RoutedHelper.GetParameter(firstName)}&lastName=${MiaPlaza.RoutedHelper.GetParameter(lastName)}&_=`;
		}
	}
	export class ProfileEmailChange
	{
		public static GetDeleteEmailUrl() : string
		{
			return "/Control.ProfileEmailChange/DeleteEmail?_=";
		}
		public static GetChangeEmailUrl() : string
		{
			return "/Control.ProfileEmailChange/ChangeEmail?_=";
		}
	}
	export class TeamMemberships
	{
		public static GetTeamsUrl(owner: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.TeamMemberships/GetTeams?owner=${MiaPlaza.RoutedHelper.GetParameter(owner)}&_=`;
		}
	}
}
export namespace MiaPlaza.Control.Video {
	export class VimeoVideoPlayer
	{
		public static GetReportVideoAsFullyWatchedUrl(video: string | MiaPlaza.IPlaceholder, lesson: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Video.VimeoVideoPlayer/ReportVideoAsFullyWatched?video=${MiaPlaza.RoutedHelper.GetParameter(video)}&lesson=${MiaPlaza.RoutedHelper.GetParameter(lesson)}&_=`;
		}
	}
}
export namespace MiaPlaza.Control.ProfilePage {
	export class ProfilePage
	{
		public static GetFriendsUrl(member: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.ProfilePage.ProfilePage/GetFriends?member=${MiaPlaza.RoutedHelper.GetParameter(member)}&_=`;
		}
		public static GetPublishedNewspaperArticlesUrl(member: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.ProfilePage.ProfilePage/GetPublishedNewspaperArticles?member=${MiaPlaza.RoutedHelper.GetParameter(member)}&_=`;
		}
	}
}
export namespace MiaPlaza.Control.Games.Multiplayer {
	export class MultiplayerConstruction
	{
		public static GetgoBack_ClickUrl(instance: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Games.Multiplayer.MultiplayerConstruction/goBack_Click?instance=${MiaPlaza.RoutedHelper.GetParameter(instance)}&_=`;
		}
	}
}
export namespace MiaPlaza.Control.Games.MiniWorldHouseInside {
	export class InventoryList
	{
		public static GetInventoryItemsUrl(owner: string | MiaPlaza.IPlaceholder, category: string | MiaPlaza.IPlaceholder) : string
		{
			return `/Control.Games.MiniWorldHouseInside.InventoryList/GetInventoryItems?owner=${MiaPlaza.RoutedHelper.GetParameter(owner)}&category=${MiaPlaza.RoutedHelper.GetParameter(category)}&_=`;
		}
	}
}
export namespace MiaPlaza.Content.Admin {
	export class Accounts
	{
		public static GetMembersOnlineUrl() : string
		{
			return "/Content.Admin.Accounts/GetMembersOnline?_=";
		}
	}
	export class Bozo
	{
		public static GetBozosUrl() : string
		{
			return "/Content.Admin.Bozo/GetBozos?_=";
		}
	}
	export class GameUnitsVideos
	{
		public static GetSavePriorityUrl(gameVideoLink: [string, string] | MiaPlaza.IPlaceholder, priority: number | MiaPlaza.IPlaceholder) : string
		{
			return `/Content.Admin.GameUnitsVideos/SavePriority?gameVideoLink=${MiaPlaza.RoutedHelper.GetParameter(gameVideoLink)}&priority=${MiaPlaza.RoutedHelper.GetParameter(priority)}&_=`;
		}
	}
	export class ImageUpload
	{
		public static GetUploadedImagesUrl() : string
		{
			return "/Content.Admin.ImageUpload/GetUploadedImages?_=";
		}
	}
	export class Newsletter
	{
		public static GetNewslettersUrl() : string
		{
			return "/Content.Admin.Newsletter/GetNewsletters?_=";
		}
	}
}
